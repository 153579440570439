import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import { Delete, Add, IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { noop } from 'lodash';

import ToDoListItemForm from './toDoListItemForm';
import UsersDropdown from './usersDropdown';
import Hidden from './hidden';
import colors from '../lib/colors';
import fetch from '../lib/get';


 class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { displayForm: false, assignDropDownIsHidden: this.props.showAssign }
  }
  toggleDependency = () => {
    this.setState({displayForm: !this.state.displayForm});
    if (!this.state.displayForm && !this.props.expanded) {
      this.props.updateExpand(this.props.listItem.itemUuid);
    }
  }
  assign = async (userUuid) => {
      let formData = new FormData()
      formData.append("itemUuid", this.props.listItem.itemUuid);
      formData.append("assignToUuid", userUuid);
      this.setState({ value: "" });
      let res = await fetch("/toDoLists/assignItem", {
        method: 'post',
        credentials: 'include',
        body: formData,
      });
      let data = res.body;
      if (data.success === true) {
        this.props.updateFunction();
      }
  }
  render() {
    const { listItem, orgUsers, updateExpand, expanded, dependencyHidden } = this.props;
    const { children, itemTitle, displayName, itemUuid, listUuid, complete, assignedToDisplayName } = listItem;
    let ownershipString = "";
    ownershipString = displayName;
    if (assignedToDisplayName.length > 0) {
      ownershipString = assignedToDisplayName;
    }
    return (
      <MuiListItem button={false} dense={true} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '1.5em', textDecoration: complete ? 'line-through' : 'none'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div onClick={() => { this.props.onClickFunction(listItem) }}>
              {itemTitle} 
          </div>
          {dependencyHidden && children.length > 0 ? (
              <IconButton color="secondary" aria-label="add" onClick={() => updateExpand(itemUuid)}>
                  { expanded ? <IndeterminateCheckBoxOutlined /> : <Add />}
              </IconButton>
          ) : null}
        </div>
        <div style={{ fontStyle: 'italic', fontSize: '0.75em' }}>
          <Hidden hidden={!dependencyHidden}>
            
            <span style={{color: colors.secondaryColor}}>{ownershipString}</span> - 
            <span style={{color: colors.primaryColor}} onClick={this.toggleDependency}>Add Dependency </span> 
            {this.state.displayForm ? <ToDoListItemForm updateFunction={this.props.updateFunction} parentUuid={itemUuid} listUuid={listUuid} /> : null}
            
          </Hidden>
          <Hidden hidden={this.state.assignDropDownIsHidden}><UsersDropdown selectFunction={this.assign} orgUsers={orgUsers} /></Hidden>
          <Hidden hidden={!dependencyHidden}>
          <IconButton onClick={() => { this.props.deleteFunction(listItem)}}>
            <Delete />
          </IconButton>
          </Hidden>
        </div>
      </MuiListItem>
    );
  }
}

ListItem.defaultProps = {
  listItem: {},
  orgUsers: [],
  updateExpand: noop,
  onClickFunction: noop,
  expanded: false,
  dependencyHidden: false,
  showAssign: true,
};

ListItem.propTypes = {
  listItem: PropTypes.object.isRequired,
  orgUsers: PropTypes.array.isRequired,
  updateExpand: PropTypes.func.isRequired,
  onClickFunction: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  dependencyHidden: PropTypes.bool.isRequired,
  showAssign: PropTypes.bool.isRequired,
};

export default ListItem;
