import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SvgColor from 'react-svg-color';

import fetch from '../lib/get';

import Envelope from '../icons/envelope.svg';

import themes from '../lib/mui-theme';
import colors from '../lib/colors'
import { Domain } from '@material-ui/icons';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
};

class Notifier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: "",
            newMessageCount: 0,
            messages: [],
        }
    }

    async getNotifications() {
        let res = await fetch(`/notifications/getNotifications`, { mode: 'cors', credentials: "include", method: "get" })
        let data = res.body
        let newMessages = 0;
        let color = "";
        data.status.forEach(function(comment) {
            if (comment.isRead == false) {
                newMessages++;
                color = colors.secondaryColor;
            }
        })
        this.setState({color: color, messages: data.status, newMessageCount: newMessages})
    }

    componentDidMount() {
        this.getNotifications();
    }
    
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                    <Link to={"/notifications/"}>
                        <SvgColor svg={Envelope} width={25} colors={[this.state.color, this.state.color, this.state.color, this.state.color]} />
                    </Link>
                </div>
                <div style={{marginRight:'4px'}}>
                    {this.state.newMessageCount}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Notifier);
