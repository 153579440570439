import React, { Component } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import MUITextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; 
import DateFnsUtils from '@date-io/date-fns';


import Layout from '../components/layout';
import Header from '../components/header';
import ToDoList from '../components/toDoList';
import CreateListForm from '../components/createListForm';
import fetch from '../lib/get';
import colors from '../lib/colors'
import Hidden from '../components/hidden'
import Button from '../components/button';
import TextField from '../components/textField';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    DateTimePicker,
  } from '@material-ui/pickers';

import get from '../lib/get';

const styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginTop: '20px',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 50px',
      minWidth: '300px',
      flex: 1,
    },
    sectionTitle: {
      fontSize: '1.5rem',
      color: 'black',
    },
    fields: {
      margin: '10px 0',
      width: '200px',
    },
    addItem: {
      alignSelf: 'center',
      width: '200px',
      marginTop: '20px',
      backgroundColor: colors.primaryColor,
    },
    addDetail: {
      width: '100px',
    },
    cancel: {
      backgroundColor: colors.errorColor,
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      backgroundColor: colors.secondaryColor,
      marginLeft: '20px',
    },
  }

class NewEventForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newCalendarEventHidden: true,
            eventStart: null,
            eventEnd: null,
            eventTitle: null,
            eventDescription: null,
            events: [],
        }
    }

    handleChangeEventStart = (e) => {
        console.log(e);
        this.setState({ eventStart: e});
    }

    handleChangeEventEnd = (e) => {
        console.log(e);
        this.setState({ eventEnd: e});
    }

    handleChangEventTitle = (e) => {
        this.setState({ eventTitle: e.target.value, error: false, success: false });
    }

    handleChangeEventDescription = (e) => {
        this.setState({ eventDescription: e.target.value, error: false, success: false });
    }

    toggleNewCalendarEventHidden = () => {
        this.setState({newCalendarEventHidden: !this.state.newCalendarEventHidden});
    }

    handleSubmit = async (event) => {      
        event.preventDefault();
        let formData = new FormData()
        formData.append("eventTitle", this.state.eventTitle);
        if (this.state.eventStart !== null) {
            console.log(this.state.eventStart);
            formData.append("eventStart", this.state.eventStart.toISOString().slice(0, 19).replace("T", " "));
        }
        if (this.state.eventEnd !== null) {
            formData.append("eventEnd", this.state.eventEnd.toISOString().slice(0, 19).replace("T", " "));
        }
        formData.append("eventDescription", this.state.eventDescription);
        this.setState({ value: "" });
        let res = await fetch("/events/addEvent", {
            method: 'post',
            credentials: 'include',
            body: formData,
        });

        if (res.body.success === true) {
            this.props.refreshFunction();
            this.toggleNewCalendarEventHidden();
            this.setState({evenStart: null, eventEnd: null, eventTitle: null, eventDescription: null});
        }
    }

    render() {
        return (
            <React.Fragment>
                <Hidden hidden={this.state.newCalendarEventHidden}>
                    <div style={{paddingBottom:'20px', width: '50%'}}>
                        <h2>New Event:</h2>
                        <form style={styles.forms}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label="Event Title" action={this.handleChangEventTitle} value={this.state.eventTitle}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            id="time-picker"
                                            label="Start Time"
                                            defaultValue={null}
                                            placeholder="yyyy-mm-dd"
                                            value={this.state.eventStart}
                                            onChange={this.handleChangeEventStart}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            id="time-picker"
                                            label="End Time"
                                            defaultValue={null}
                                            placeholder="yyyy-mm-dd"
                                            value={this.state.eventEnd}
                                            onChange={this.handleChangeEventEnd}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                <MUITextField
                                    id="filled-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    variant="filled"
                                    value={this.state.eventDescription}
                                    onChange={this.handleChangeEventDescription}
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#FFFFFF',
                                    }}
                                    />
                                </Grid>
                            </Grid>
                        </form>   
                    </div>
                    <Button style={{backgroundColor: colors.secondaryColor, marginRight: '20px'}} action={this.handleSubmit}>Submit</Button>
                    <Button style={{backgroundColor: colors.thirdColor, color: '#000000'}} action={this.toggleNewCalendarEventHidden}>Cancel</Button>
                </Hidden>
                <Hidden hidden={!this.state.newCalendarEventHidden}>
                    <Button style={{backgroundColor: colors.secondaryColor}} action={this.toggleNewCalendarEventHidden}>Create Event</Button>
                </Hidden>
            </React.Fragment>
        )
    }
}

export default withRouter(NewEventForm);
