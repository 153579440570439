import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../components/button';
import Logo from '../components/logo';
import colors from '../lib/colors';

const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100px',
    backgroundColor: colors.background,
    marginBottom: '10px',
    flexWrap: 'wrap',
  },
  title: {
    color: colors.gray,
    display: 'flex',
    flexDirection: 'column',
  },
  links: {
    display: 'flex',
    marginRight: '20px',
  },
  link: {
    cursor: 'pointer',
    margin: '0 10px',
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 2,
    margin: '30px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 3,
    margin: '30px 0',
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '50px',
  },
  text: {
    marginBottom: '5px',
  },
  logo: {
    width: '200px',
    marginBottom: '60px',
  },
  strike: {
    backgroundColor: colors.text,
    width: '100%',
    height: '5px',
    position: 'relative',
  },
  button: {
    height: '50px',
    width: '180px',
    fontWeight: 900,
    fontSize: '15px',
  },
};

class Home extends Component {
  render() {
    return (
      <div style={styles.app}>
        <div style={styles.header}>
          <div className="homeTitle" style={styles.title}>
            <div>TEAM SOFTWARE MADE FOR YOU</div>
            <div>PLANS STARTING AT $0</div>
          </div>
          <div className="homeNav" style={styles.links}>
            <div style={styles.link}>PRICING</div>|
            <div style={styles.link}>FEATURES</div>|
            <div style={styles.link} onClick={() => this.props.history.push('/register')}>SIGNUP</div>|
            <div style={styles.link} onClick={() => this.props.history.push('/login')}>LOGIN</div>
          </div>
        </div>
        <div className="homeSection" style={{ backgroundColor: colors.secondaryColor }}>
          <div className="logoBox" style={styles.logoBox}>
            <div style={styles.logo}><Logo /></div>
            <Button style={styles.button} action={() => this.props.history.push('/register')}>
              FIND OUT MORE
            </Button>
          </div>
          <div className="content" style={styles.content}>
            <div className="contentHeader">
              <div>YOU DESERVE BETTER</div><div> TEAMWORK SOFTWARE.</div>
              <div className="contentText" style={styles.contentText}>
                <div style={{...styles.text, padding: '0 10px', marginBottom: 0}}>MADE FOR THE ENTERPRISE</div>
                <div className="strike" style={styles.strike}/>
                <div style={{...styles.text, padding: '0 10px', marginBottom: 0}}>EXPENSIVE</div>
                <div className="strike" style={styles.strike}/>
                <div style={{...styles.text, padding: '0 10px', marginBottom: 0}}>HIDDEN PRICES</div>
                <div className="strike" style={styles.strike}/>
                <div style={{...styles.text, padding: '0 10px', marginBottom: 0}}>ONE SIZE FITS NONE</div>
                <div className="strike" style={styles.strike}/>
              </div>
            </div>
          </div>
        </div>
        <div className="homeSection" style={{ backgroundColor: colors.background }}>
          <div className="content" style={styles.content}>
            <div className="contentHeader">
              <div>KEEP TRACK OF</div><div>YOUR ASSETS. SIMPLE.</div>
              <div className="contentText" style={styles.contentText}>
                <div style={styles.text}>DELEGATE OWNERSHIP</div>
                <div style={styles.text}>TRACK LOCATION</div>
                <div style={styles.text}>MONITOR STATUS</div>
                <div style={styles.text}>STARTS AT $0</div>
              </div>
            </div>
          </div>
          <div className="logoBox" style={styles.logoBox}>
            <div style={styles.logo}><Logo /></div>
            <Button style={styles.button} action={() => this.props.history.push('/register')}>
              FIND OUT MORE
            </Button>
          </div>
        </div>
        <div className="homeSection" style={{ backgroundColor: colors.secondaryColor }}>
          <div className="logoBox" style={styles.logoBox}>
            <div style={styles.logo}><Logo /></div>
            <Button style={styles.button} action={() => this.props.history.push('/register')}>
              FIND OUT MORE
            </Button>
          </div>
          <div className="content" style={styles.content}>
            <div className="contentHeader">
              <div>YOUR CUSTOMERS'</div><div>NEW BEST FRIEND</div>
              <div className="contentText" style={styles.contentText}>
                <div style={styles.text}>BETTER PHONE CALLS</div>
                <div style={styles.text}>REMOTE ANSWERING</div>
                <div style={styles.text}>A CRM MADE FOR YOUR BUSINESS</div>
                <div style={styles.text}>STARTS AT $0</div>
              </div>
            </div>
          </div>
        </div>
        <div className="homeSection" style={{ backgroundColor: colors.background }}>
          <div className="content" style={styles.content}>
            <div className="contentHeader">
              <div>MISSION CONTROL FOR</div><div>YOUR NEXT MOONSHOT</div>
              <div className="contentText" style={styles.contentText}>
                <div style={styles.text}>ADVANCED PLANNING TOOLS</div>
                <div style={styles.text}>SIMPLE TO USE</div>
                <div style={styles.text}>SHARE REPORTS WITH YOUR TEAM</div>
                <div style={styles.text}>STARTS AT $0</div>
              </div>
            </div>
          </div>
          <div className="logoBox" style={styles.logoBox}>
            <div style={styles.logo}><Logo /></div>
            <Button style={styles.button} action={() => this.props.history.push('/register')}>
              FIND OUT MORE
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
