import ListItem from './listItem'
import React, { Component, Fragment } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';

import fetch from '../lib/get';
import colors from '../lib/colors'
import Button from './button';
import ToDoListItemForm from './toDoListItemForm';
import arrayToTree from 'array-to-tree';

import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';
import { Tab } from 'react-tabs';

var tableStyle = {
    fontSize: '1.0em',
    verticalAlign: 'top',
}

function makeList(contacts) {
    var resp = [];
    contacts.forEach(function(contact) {
        resp.push(<span>{contact.firstName}</span>)
    })
    return(resp);
}

function getEmail(contact) {
    var email = "";
    contact.attributes.forEach(function(attribute) {
        if (attribute.attributeName == "email") {
            email = attribute.attributeValue;
        }
    })
    return(email);
}

function getPhoneNumber(contact) {
    var phoneNumber = "";
    contact.attributes.forEach(function(attribute) {
        if (attribute.attributeName == "phonenumber") {
            phoneNumber = attribute.attributeValue;
        }
    })
    return(phoneNumber);
}

function showAttributes(contact) {
    var resp = [];
    contact.attributes.forEach(function(attribute) {
        resp.push(<li>{attribute.attributeName}:{attribute.attributeValue}</li>)
    });
    return(resp);
}

class ContactTable extends Component {

    constructor(props) {
        super(props);
        this.tableRows = this.tableRows.bind(this);
        console.log("props:");
        console.log(props);
    }

    tableRows = (contacts) => {
        var resp = [];
        contacts.forEach((contact) => {
            resp.push(<TableRow>
                        <TableCell style={tableStyle}><Link to={`/crm/contact/${contact.contactUuid}`}>{contact.firstName}</Link></TableCell>
                        <TableCell style={tableStyle}><Link to={`/crm/contact/${contact.contactUuid}`}>{contact.lastName}</Link></TableCell>
                        <TableCell style={tableStyle}>{contact.email}</TableCell>
                        <TableCell style={tableStyle}>{contact.phoneNumber}</TableCell>
                        <TableCell style={tableStyle}><Link to={`/crm/company/${contact.companyName}`}>{contact.companyName}</Link></TableCell>
                        <TableCell style={tableStyle}><ul>{showAttributes(contact)}</ul></TableCell>
                      </TableRow>);
        })
        return(resp);
    }

    render() {    
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={tableStyle}>First Name</TableCell>
                        <TableCell style={tableStyle}>Last Name</TableCell>
                        <TableCell style={tableStyle}>Email Address</TableCell>
                        <TableCell style={tableStyle}>Phone Number</TableCell>
                        <TableCell style={tableStyle}>Company Name</TableCell>
                        <TableCell style={tableStyle}>Additional Attributes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.tableRows(this.props.contacts)}
                </TableBody>
            </Table>
        )
    }
}

export default(ContactTable);