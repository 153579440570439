import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import get from '../lib/get';

class PrivateRoute extends Component {
  state = {
    isAuthenticated: false,
    loading: true,
    error: false,
  }
  async componentDidMount() {
    try{
      const res = await get('/auth/whoami', { credentials: 'include', method: 'GET' });
      this.setState({ isAuthenticated: res.body.success, loading: false });
    } catch(err) {
      this.setState({ isAuthenticated: false, loading: false, error: err.message })
    }
  }
  render() {
    const { children, ...rest } = this.props;
    if(this.state.loading) {
      return null;
    }
    return (
      <Route
        {...rest}
        render={({ location }) =>
          this.state.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location, error: this.state.error }
              }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
