import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

const styles = {
  container: {
    marginTop: '20px',
  },
};

function Layout(props) {
    const { classes } = props;
    return (
        <Container className={classes.container}>
            {props.children}
        </Container>
    );
}

export default withStyles(styles)(Layout);