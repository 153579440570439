import React, { Component } from 'react';
import query from 'query-string';
import PropTypes from 'prop-types';
import { endsWith, noop } from 'lodash';

import { Typography, Slide } from '@material-ui/core';
import TextField from '../components/textField';
import Button from '../components/button';
import Error from '../components/error';
import Success from '../components/success';
import colors from '../lib/colors';
import get from '../lib/get';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    margin: '100px auto',
  },
  subheader: {
    fontSize: '1.2rem',
    color: colors.secondaryDark,
  },
  forms: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0',
  },
  link: {
    cursor: 'pointer',
    color: colors.primaryLight,
    fontSize: '1.2rem',
    marginTop: '10px',
  },
};

class Auth extends Component {
  constructor(props) {
    super(props);

    this.handleLoginPage = this.handlePage.bind(this, 'login');
    this.handleRegisterPage = this.handlePage.bind(this, 'register');
    this.handleForgotPage = this.handlePage.bind(this, 'forgotPassword');
    this.handleChangeDisplayName = this.handleChange.bind(this, 'displayName');
    this.handleChangeEmail = this.handleChange.bind(this, 'email');
    this.handleChangePass = this.handleChange.bind(this, 'password');
    this.handleChangeConfirmPass = this.handleChange.bind(this, 'confirmPassword');
    this.handleLogin = this.handleLogin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
this.handleForgotPassword = this.handleForgotPassword.bind(this);
    const register = endsWith(this.props.location.pathname, 'register');
    const resetPassword = endsWith(this.props.location.pathname, 'resetPassword');
    const forgotPassword = endsWith(this.props.location.pathname, 'forgotPassword');
    
    let page = "login";
    if (register) {
      page = "register"
    }
    if (resetPassword) {
      page = "reset"
    }
    if (forgotPassword) {
      page = "forgotPassword"
    }
    this.state = {
      page: page,
      error: false,
      success: false,
      displayName: '',
      password: '',
      email: '',
      confirmPassword: '',
    };
  }
  async componentDidMount() {
    return get('/auth/whoami', { credentials: 'include', method: 'GET' })
      .then(() => this.props.history.push('/dashboard')).catch(noop);
  }
  handlePage(page) {
    return this.setState({ page, error: false, success: false, password: '', confirmPassword: '' });
  }
  handleChange(field, e) {
    this.setState({ [field]: e.target.value });
  }
  
  async handleForgotPassword() {
    const { email } = this.state;
    const queryObject = { email: email};
    const queryString = query.stringify(queryObject)
    try {
      await get(`/auth/requestPasswordReset?${queryString}`, { credentials: 'include', method: 'GET' }).then(() => this.setState({ success: 'Password reset request submitted.  Please check your email.', page: 'login' }));
    } catch(err) {
      this.setState({ error: err.message });
    }
  }
  async handlePasswordReset() {
    const { password, confirmPassword } = this.state;
    if(password !== confirmPassword) {
      return this.setState({ error: 'Passwords must match.' });
    }
    const queryObject = { pass1: password, pass2: confirmPassword};
    const urlParms = query.parse(this.props.location.search);
    if(urlParms.passwordResetCode) {
      queryObject.passwordResetCode = urlParms.passwordResetCode;
    }
    const queryString = query.stringify(queryObject)
    try {
      await get(`/auth/resetPassword?${queryString}`, { credentials: 'include', method: 'GET' }).then(() => this.setState({ success: 'Password has been reset.', page: 'login' }));
    } catch(err) {
      this.setState({ error: err.message });
    }
  }
  async handleLogin() {
    this.setState({ error: false });
    const { email, password } = this.state;
    const queryString = query.stringify({ email, password })
    try {
      await get(`/auth/login?${queryString}`, { credentials: 'include', method: 'GET' }).then(() => this.props.history.push('/dashboard'));
    } catch(err) {
      this.setState({ error: err.message });
    }
  }
  async handleRegister() {
    const { displayName, password, confirmPassword, email } = this.state;
    if(password !== confirmPassword) {
      return this.setState({ error: 'Passwords must match.' });
    }
    const queryObject = { displayName, email, pass1: password, pass2: confirmPassword, inviteCode: '' };
    const invite = query.parse(this.props.location.search);
    if(invite.inviteCode) {
      queryObject.inviteCode = invite.inviteCode;
    }
    const queryString = query.stringify(queryObject)
    try {
      await get(`/auth/register?${queryString}`, { credentials: 'include', method: 'GET' }).then(() => this.setState({ success: 'User successfully created.', page: 'login' }));
    } catch(err) {
      this.setState({ error: err.message });
    }
  }
  render() {
    const { error, success } = this.state;
    const page = {
      
      forgotPassword:(

        <form style={styles.forms}>
          <TextField label="Email" type="text" action={this.handleChangeEmail}/>
          <Button action={this.handleForgotPassword}>Reset</Button>
          <div style={styles.link} onClick={this.handleLoginPage}>Cancel</div>
        </form>
      ),
      reset:(
        <form style={styles.forms}>
          <TextField label="Password" type="password" action={this.handleChangePass}/>
          <TextField label="Confirm Password" type="password" action={this.handleChangeConfirmPass}/>
          <Button action={this.handlePasswordReset}>Reset</Button>
        </form>
      ),
      login:(
        <form style={styles.forms}>
          <TextField label="Username/Email" action={this.handleChangeEmail}/>
          <TextField label="Password" type="password" action={this.handleChangePass}/>
          <Button action={this.handleLogin}>Login</Button>
          <div style={styles.link} onClick={this.handleRegisterPage}>Sign up</div>
          <div style={styles.link} onClick={this.handleForgotPage}>Forgot Password</div>
        </form>
      ),
      register: (

        <form style={styles.forms}>
          <TextField label="Name" action={this.handleChangeDisplayName}/>
          <TextField label="Email" action={this.handleChangeEmail}/>
          <TextField label="Password" type="password" action={this.handleChangePass}/>
          <TextField label="Confirm Password" type="password" action={this.handleChangeConfirmPass}/>
          <Button action={this.handleRegister}>Signup</Button>
          <div style={styles.link} onClick={this.handleLoginPage}>Cancel</div>
        </form>

      )
    };
    const active = page[this.state.page];
    return (
      <Slide direction="up" in mountOnEnter unmountOnExit key={this.state.page}>

    <div style={styles.container}>
        <Typography variant="h1">
          Tensile
        </Typography>
        <Typography variant="h5">
          Teamwork intelligence made for you
        </Typography>
        {active}
        <Error error={error}/>
        <Success success={success}/>
      </div>
      </Slide>
    );
  }
}

Auth.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Auth;
