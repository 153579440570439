import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import { Add, IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { noop } from 'lodash';

import ToDoListItemForm from './toDoListItemForm';
import UsersDropdown from './usersDropdown';
import UserString from './userString';
import Hidden from './hidden';
import colors from '../lib/colors';
import fetch from '../lib/get';


 class ItemFragment extends Component {
  constructor(props) {
    super(props);
    this.state = { displayForm: false, assignDropDownIsHidden: this.props.showAssign }
  }

  assignmentString = (listItem) => {
      if (listItem.assignedToDisplayName.length === 0) {
          return(null);
      } else {
        return(
            <span style={{paddingLeft: "0px", fontSize: '0.75em'}}>
                Assigned to <UserString displayName={listItem.assignedToDisplayName} />
            </span>
        );
      }
  }
  
  render() {
    const { listItem, orgUsers, updateExpand, expanded, dependencyHidden } = this.props;
    const { children, itemTitle, displayName, itemUuid, listUuid, complete, assignedToDisplayName, listTitle } = listItem;

    return (
      <MuiListItem button={false} dense={true} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '1.5em', textDecoration: complete ? 'line-through' : 'none'}}>
        <div onClick={() => {this.props.onClickFunction(listItem)}}>
            <span style={{color: colors.secondaryColor}}>{listTitle}</span>: {itemTitle} {this.assignmentString(listItem)}
        </div>
      </MuiListItem>
    );
  }
}

ItemFragment.defaultProps = {
  listItem: {},
  orgUsers: [],
  updateExpand: noop,
  onClickFunction: noop,
  expanded: false,
  dependencyHidden: false,
  showAssign: true,
};

ItemFragment.propTypes = {
  listItem: PropTypes.object.isRequired,
  orgUsers: PropTypes.array.isRequired,
  updateExpand: PropTypes.func.isRequired,
  onClickFunction: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  dependencyHidden: PropTypes.bool.isRequired,
  showAssign: PropTypes.bool.isRequired,
};

export default ItemFragment;