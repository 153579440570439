import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import { Add, IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { noop } from 'lodash';

import ToDoListItemForm from './toDoListItemForm';
import UsersDropdown from './usersDropdown';
import UserString from './userString';
import Hidden from './hidden';
import colors from '../lib/colors';
import fetch from '../lib/get';


function isToday(date) {
    date = new Date(date);
    const today = new Date();
    let resp = true;
    if (date.getDate() != today.getDate()) {
        resp = false;
    }
    if (date.getMonth() != (today.getMonth())) {
        console.log(date.getMonth());
        console.log(date.getMonth() == (today.getMonth()+1));
        resp = false;
    }
    if (date.getFullYear() != today.getFullYear()) {
        resp = false;
    }
    return resp;
}


function prettyDate(date) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    date = new Date(date);
    console.log(date);
    console.log(date.getDay());
    //let resp = "${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} - ${days[date.getDay()]}`;
    return(
        <div style={{display: 'flex', flexDirection: 'column', width: '90px'}}>
            <div>{days[date.getDay()]}</div>
            <div>{date.getHours() > 12 ? date.getHours() - 12 : date.getHours()}:{String(date.getMinutes()).padStart(2,0)}</div>
        </div>
    );
}

 class EventFragment extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  render() {
    const { date, title, } = this.props;
    const today = new Date();

    return (
      <MuiListItem button={false} dense={true} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '1.5em', textDecoration: 'none'}}>
        <div style={{display: 'flex', flexDirection: 'row'}} >
            <div>
                {prettyDate(date)}
            </div>
            <div>
                {title}
            </div>
        </div>
      </MuiListItem>
    );
  }
}

export default EventFragment;