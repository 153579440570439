import React, { Component } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import MUITextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; 
import DateFnsUtils from '@date-io/date-fns';


import Layout from '../components/layout';
import Header from '../components/header';
import NewEventForm from '../components/newEventForm';
import ToDoList from '../components/toDoList';
import CreateListForm from '../components/createListForm';
import fetch from '../lib/get';
import colors from '../lib/colors'
import Hidden from '../components/hidden'
import Button from '../components/button';
import TextField from '../components/textField';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    DateTimePicker,
  } from '@material-ui/pickers';

import get from '../lib/get';

const styles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginTop: '20px',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 50px',
      minWidth: '300px',
      flex: 1,
    },
    sectionTitle: {
      fontSize: '1.5rem',
      color: 'black',
    },
    fields: {
      margin: '10px 0',
      width: '200px',
    },
    addItem: {
      alignSelf: 'center',
      width: '200px',
      marginTop: '20px',
      backgroundColor: colors.primaryColor,
    },
    addDetail: {
      width: '100px',
    },
    cancel: {
      backgroundColor: colors.errorColor,
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      backgroundColor: colors.secondaryColor,
      marginLeft: '20px',
    },
  }

class Calendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newCalendarEventHidden: true,
            eventStart: null,
            eventEnd: null,
            eventTitle: "",
            events: [],
        }
    }

    handleChangeEventStart = (e) => {
        console.log(e);
        this.setState({ eventStart: e});
    }

    handleChangeEventEnd = (e) => {
        console.log(e);
        this.setState({ eventEnd: e});
    }

    handleChangEventTitle = (e) => {
        this.setState({ eventTitle: e.target.value, error: false, success: false });
    }

    toggleNewCalendarEventHidden = () => {
        this.setState({newCalendarEventHidden: !this.state.newCalendarEventHidden});
    }

    handleDateClicked = (event) => {
        this.setState({newCalendarEventHidden: false})
        console.log(event);
    }

    handleEventClicked = (event) => {
        console.log(event.event.id);
        this.props.history.push("/events/event/" + event.event.id);
    }

    processEvents = (events) => {
        let _events = []
        events.forEach(event => {
            _events.push({title: event.eventTitle, id: event.eventUuid, start: event.eventStart, end: event.eventEnd})
        })
        this.setState({events: _events})
        console.log(this.state);
    }

    componentDidMount = () => {
        this.getEvents();
    }

    getEvents = async () => {
        const res = await get('/events/getEvents', { credentials: 'include', method: 'GET' });
        this.processEvents(res.body.status);
    }

    render() {
        return (
            <Layout>
                <Header />
                
                <Paper>
                    <NewEventForm refreshFunction={this.getEvents} />
                    <div style={{paddingTop: '20px', paddingBottom: '100px'}}>
                        <FullCalendar
                            plugins={[ dayGridPlugin, interactionPlugin ]}
                            initialView="dayGridMonth"
                            displayEventEnd={true}
                            dateClick={this.handleDateClicked}
                            eventClick={this.handleEventClicked}
                            style={{paddingTop: '100px'}}
                            events={this.state.events}
                        />
                    </div>
                </Paper>
            </Layout>
        )
    }
}

export default withRouter(Calendar);
