import React, { Component } from 'react';
import TextField from '../components/textField';
import Button from '../components/button';
import fetch from '../lib/get';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';


class CreateListForm extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
    }
    handleChange = (event) => {
        this.setState({ value: event.target.value });
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        let data = {
            title: this.state.value,
            description: "",
            listItems: [],
        };
        this.setState({ value: "" });
        let res = await fetch("/toDoLists/createToDoList", {
            method: 'post',
            credentials: 'include',
            body: JSON.stringify(data),
        })
        data = res.body
        if (data.success === true) {
            this.props.updateFunction();
            this.props.history.push(`/toDoLists/list/${data.status}`)
        }
    }
    render() {
        return (
            <div>
                <Typography variant="h3">
                    Create a new list
                </Typography>
                <TextField autoFocus fullWidth value={this.state.value} id="title" label="List Title" action={this.handleChange} maxLength="36" />
                <br /><Button style={{marginTop: '20px'}} action={this.handleSubmit}>Create List</Button>
            </div>
        )
    }
}

export default CreateListForm;
