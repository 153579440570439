import React, { Component } from 'react';
import { forEach, reject } from 'lodash';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { Paper, Dialog, DialogTitle, Grid } from '@material-ui/core';

import get from '../lib/get';
import Layout from '../components/layout';
import Error from '../components/error';
import Success from '../components/success';
import Header from '../components/header';
import Comments from '../components/comments';
import ContactTable from '../components/contactTable';
import AttributeAdd from '../components/attributeAdd';
import TextField from '../components/textField';
import Button from '../components/button';
import Hidden from '../components/hidden';

import colors from '../lib/colors';



const styles = {
  dialogMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    fontSize: '16px',
    margin:'20px',
  },
}

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      deleteModalIsOpen: false,
      event: 
        {
          eventUuid: null,
          userUuid: null,
          eventTitle: null,
          eventStart: null,
          eventEnd: null,
          eventDescription: null,
          creationDate: null,
          orgId: null,
          endSpecified: null,
          displayName: null,
        }
      }
  }

  async componentDidMount() {
    try{
      const path = `/events/getEvent?eventUuid=${this.props.match.params.eventUuid}`
      const res = await get(path, { credentials: 'include', method: 'GET' });
      this.setState({ event: res.body.status[0] });
    }catch(err){
      this.setState({ error: err.message })
    }
  }

  formatDate = (date) => {
    let d = new Date(date)
    return d.toString();
  }

  toggleDeleteModalIsOpen = () => {
    this.setState({deleteModalIsOpen: !this.state.deleteModalIsOpen})
  }

  handleDeleteEvent = async () => {
    const res = await get("/events/deleteEvent?eventUuid=" + this.state.event.eventUuid, { credentials: 'include', method: 'GET' });
    if (res.body.success == true) {
      this.props.history.push("/calendar/");
    }
    console.log(res);
  }

  render() {
    return (
      <Layout>
        <Header />
        <div style={styles.content}>
          <div style={styles.section}>
            <header style={styles.sectionTitle}>
            </header>
          </div>
          <div style={styles.section}>

            <Paper>
              <Link to={`/calendar/`}>Calendar</Link>
                  <h4 style={{color: colors.primaryColor}}>{this.state.event.eventTitle}</h4>
                  <h1>Event Start: {this.formatDate(this.state.event.eventStart)}</h1>
                  <h1>Event End: {this.state.event.endSpecified ? this.formatDate(this.state.event.eventEnd): "Unspecified"}</h1>
                  <h1>Description:</h1>
                  <Button action={this.toggleDeleteModalIsOpen}>Delete Item</Button>
            </Paper>
            <Paper style={{marginTop: "20px", marginBottom: "100px"}}>
              <Comments parentUuid={this.state.event.eventUuid} title={this.state.event.eventTitle}/>
            </Paper>
          </div>
        </div>

        <Dialog open={this.state.deleteModalIsOpen} onClose={() => this.setState({ deleteModalIsOpen: false })}>
          <DialogTitle>Are you sure you want to delete this calendar event?</DialogTitle>
          <div style={styles.dialogMessage}>
            <Button action={this.handleDeleteEvent}>Delete</Button>
          </div>
        </Dialog>
      </Layout>
    );
  }
}

export default withRouter(Event);
