import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { reject, forEach } from 'lodash';
import { ButtonGroup, Button, Paper } from '@material-ui/core';
import queryString from 'query-string';

import Layout from '../components/layout';
import Header from '../components/header';
import Table from '../components/table';
import AttributeAdd from '../components/attributeAdd';
import get from '../lib/get';
import colors from '../lib/colors';

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  goBack: {
    width: '100px',
    marginTop: '20px',
  },
  searchTitle: {
    fontSize: '1.5rem',
    marginTop: '20px',
  },
  searchGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchFields: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBotton: '10px',
  },
  cancel: {
    backgroundColor: colors.errorColor,
  },
  add: {
    backgroundColor: colors.successDark,
    margin: '15px 20px',
  },
  update: {
    backgroundColor: colors.successDark,
    margin: '15px 0',
  },
  clear: {
    backgroundColor: colors.errorDark,
    margin: '15px 20px',
  },
};

class Items extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.addSearch = this.addSearch.bind(this);

    this.state = {
      items: [],
      attributes: [],
      currentSearch: [],
    };
  }
  async componentDidMount() {
    if(this.props.location.search) {
      const search = queryString.parse(this.props.location.search)
      const keys = Object.keys(search);
      const attributes = keys.map((key) => {
        return { attributeName: key, attributeValue: search[key] };
      });
      this.setState({ attributes });
      return this.handleSearch(attributes);
    }
    try{
      const res = await get('/things/getThings', { credentials: 'include', method: 'GET' });
      this.setState({ items: res.body.status });
    }catch(err){
      this.setState({ error: err.message })
    }
  }
  async handleSearch(search) {
    const attributes = reject(search, ['attributeName', '']);
    if(attributes.length > 0) {    
      const body = JSON.stringify(attributes);
      try {
        const res = await get('/things/searchThings', { credentials: 'include', method: 'POST', body });
        this.setState({ items: res.body.status, currentSearch: attributes });
        const string = {};
        forEach(attributes, (attr) => {
          string[attr.attributeName] = attr.attributeValue;
        });
        this.props.history.replace(`?${queryString.stringify(string)}`);
      } catch(err) {
        return this.setState({ error: err.message });
      }
    }
  }
  async handleClearSearch() {
    try{
      const res = await get('/things/getThings', { credentials: 'include', method: 'GET' });
      this.setState({ items: res.body.status, attributes: [], currentSearch: [] });
      this.props.history.replace('/assets/items');
    }catch(err){
      this.setState({ error: err.message })
    }
  }
  addSearch() {
    this.setState({ attributes: [...this.state.attributes, { attributeName: '', attributeValue: '' }] });
  }
  render() {
    const { attributes, items, currentSearch } = this.state;
    return (
      <Layout>
        <Header />
        <div style={styles.content}>
          <div style={styles.search}>
            <div style={styles.searchTitle}>Search by</div>
            <div style={styles.searchGroup}>
              <AttributeAdd setParentState={state => this.setState({ attributes: state })} attributes={attributes} />
            </div>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
              <Button onClick={this.addSearch}>Add Filter</Button>
              <Button onClick={() => this.handleSearch(this.state.attributes)}>Search</Button>
              <Button onClick={this.handleClearSearch}>Clear Filter</Button>
            </ButtonGroup>
          </div>
          <Paper>
            <Table history={this.props.history} rows={items} currentSearch={currentSearch} />
          </Paper>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Items);
