import React from 'react';

import Hidden from  './hidden';
import colors from '../lib/colors';

const styles = {
  error: {
    color: colors.secondaryColor,
    fontSize: '1.5rem',
    width: '100%',
    overflowWrap: 'break-word',
  },
}

function Error(props) {
  return (
    <Hidden hidden={!props.error}>
      <div style={styles.error}>{props.error}</div>
    </Hidden>
  );
}

export default Error;
