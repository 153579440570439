import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { forEach, pullAt, take, trim, trimStart, lowerCase, noop } from 'lodash';
import query from 'query-string';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import get from '../lib/get';
import AutoComplete from '../components/autoComplete';
import TextField from '../components/textField';

const styles = {
  attribute: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '10px 0',
  },
}

class AttributeAdd extends Component {
  constructor(props) {
    super(props);

    this.handleAutoComplete = this.handleAutoComplete.bind(this);
    this.handleChangeName = this.handleChangeAttribute.bind(this, 'auto');
    this.handleChangeValue = this.handleChangeAttribute.bind(this, 'value');
    this.handleCancelAttribute = this.handleCancelAttribute.bind(this);
    this.buildAttributes = this.buildAttributes.bind(this);

    this.state = {
      autoComplete: [],
    };
  }
  buildAttributes() {
    const list = [];
    const { attributes, actions } = this.props;
    forEach(attributes, (item, index) => {
      list.push(
        <div style={styles.attribute} className="attribute" key={index}>
          <AutoComplete value={item.attributeName} autoComplete={this.state.autoComplete} id={`${index}0`} label="Name" action={e => this.handleChangeName(e.target.value,  `${index}0`)} maxLength="36"/>
          <TextField value={item.attributeValue} id={`${index}1`} label="Value" action={(e) => this.handleChangeValue(e.target.value, `${index}1`)} maxLength="140"/>
          <IconButton aria-label="delete" onClick={() => this.handleCancelAttribute(index)}>
            <Delete />
          </IconButton>
          {actions}
        </div>
      );
    });
    return list;
  }
  async handleAutoComplete(queryString) {
    const autoComplete = await get(`/attributes/searchAttributes?${queryString}`, { credentials: 'include', method: 'GET' });
    this.setState({ autoComplete: take(autoComplete.body.status, 10) });
  }
  handleChangeAttribute(type, value, index) {
    const attributes = [...this.props.attributes];
    if(type === 'auto') {
      let noWhiteSpace = trim(value, ' ');
      noWhiteSpace = lowerCase(noWhiteSpace);
      if(noWhiteSpace.length > 1) {
        const queryString = query.stringify({ attributeStub: noWhiteSpace });
        this.handleAutoComplete(queryString);
      }
      attributes[index[0]].attributeName = noWhiteSpace;
      return this.props.setParentState(attributes);
    }
    const trimmed = trimStart(value);
    attributes[index[0]].attributeValue = trimmed;
    this.props.setParentState(attributes);
  }
  handleCancelAttribute(index) {
    const attributes = [...this.props.attributes];
    pullAt(attributes, index);
    this.props.setParentState(attributes);
  }
  render() {
    const attributeList = this.buildAttributes();
    return (
      <Fragment>
        {attributeList}
        {this.props.children}
      </Fragment>
    );
  }
}

AttributeAdd.defaultProps = {
  attributes: [],
  actions: null,
  setParentState: noop,
};

AttributeAdd.propTypes = {
  attributes: PropTypes.array.isRequired,
  actions: PropTypes.element,
  setParentState: PropTypes.func.isRequired,
};

export default AttributeAdd;
