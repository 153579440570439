import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Layout from '../components/layout';
import Header from '../components/header';
import ItemFragment from "../components/itemFragment";
import EventFragment from "../components/eventFragment";
import Table from '../components/table';
import ListItem from '../components/listItem';
import colors from '../lib/colors';
import get from '../lib/get';

const styles = {
  gridItem: {
    margin: '15px 0px',
    overflowY: 'auto',
  },
  paper: {
    height: '400px',
    overflow: "auto",
    scrollbarWidth: "0px",
  },
  button: {
    backgroundColor: colors.secondaryColor,
    marginLeft: '20px',
  },
}

class Dashboard extends Component {
  ws = new WebSocket('wss://api.tensile.co:8080');

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      myAssignments: [],
      allAssignments: [],
      myComplete: [],
      allComplete: [],
      futureEvents: [],
      eventsToday: [],
      eventsThisWeek: [],
    };
  }

  async getData() {
    try{
      const things = await get('/things/getThings', { credentials: 'include', method: 'GET' });
      const myAssignments = await get('/toDoLists/getAssignments', { credentials: 'include', method: 'get' });
      const allAssignments = await get('/toDoLists/getAssignments?all=true', { credentials: 'include', method: 'get' });
      const myComplete = await get('/toDoLists/getAssignments?complete=true', { credentials: 'include', method: 'get' });
      const allComplete = await get('/toDoLists/getAssignments?all=true&complete=true', { credentials: 'include', method: 'get' });
      const futureEvents = await get('/events/getFutureEvents', { credentials: 'include', method: 'get' });
      const eventsToday = await get('/events/getEventsToday', { credentials: 'include', method: 'get' });
      const eventsThisWeek = await get('/events/getEventsThisWeek', { credentials: 'include', method: 'get' });
      
      console.log("Future events:");
      console.log(futureEvents.body.status);

      this.setState({ items: things.body.status, 
        myAssignments: myAssignments.body.status, 
        allAssignments: allAssignments.body.status, 
        myComplete: myComplete.body.status, 
        allComplete: allComplete.body.status,  
        futureEvents: futureEvents.body.status,
        eventsToday: eventsToday.body.status,
        eventsThisWeek: eventsThisWeek.body.status,
      });
    }catch(err){
      this.setState({ error: err.message })
    }
  }

  async componentDidMount() {
    this.getData();
    let res = await get('/sockets/getSocketString', { credentials: "include", method: "get" });
    let data = res.body;
    let socketString = data.status;
    this.setState({socketString: socketString});
    if (this.ws.readyState === 1) {
        this.ws.send(this.state.socketString);
    }
    this.ws.onopen = () => {
        this.ws.send(this.state.socketString);
    }
    console.log(this.ws);
    this.ws.onmessage = evt => {
        if (evt.data === "update") {
          this.getData();
        }
    }
  }
  onClickFunction = (listItem) => {
    this.props.history.push(`/toDoLists/list/${listItem.listUuid}`, { listItem, showLink: true });
  }
  render() {
    const { items, myAssignments, allAssignments, myComplete, allComplete, futureEvents, eventsToday, eventsThisWeek} = this.state;
    const { classes } = this.props;
    return (
      <Layout>
        <Header />
        <Typography style={{marginTop: '25px'}} variant="h2">Dashboard</Typography>
        <Grid container spacing={2}>

          <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper} style={{height:'auto', maxHeight: '400px'}}>
              <Typography variant="h3" onClick={() => {this.props.history.push("/Calendar/")}}>Events Today</Typography>
                {eventsToday.map((event) => (
                <div onClick={() => {this.props.history.push("/events/event/" + event.eventUuid)}}> 
                  <EventFragment
                    title={event.eventTitle}
                    date={event.eventStart}
                    onClick={() => {this.props.history.push("/events/event/" + event.eventUuid)}}
                  />
                </div>
                  )
                )}
            </Paper>
          </Grid>

          <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={6}>
            <Paper className={classes.paper} style={{height:'auto', maxHeight: '400px'}}>
              <Typography variant="h3" onClick={() => {this.props.history.push("/Calendar/")}}>Events This Week</Typography>
                {eventsThisWeek.map((event) => (
                  <div onClick={() => {this.props.history.push("/events/event/" + event.eventUuid)}}> 
                    <EventFragment
                      title={event.eventTitle}
                      date={event.eventStart}
                    />
                  </div>
                  )
                )}
            </Paper>
          </Grid>

          <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={8}>
            <Paper className={classes.paper} style={{height:'auto', maxHeight: '400px'}}>
              <Typography variant="h3" style={{ marginBottom: '20px' }} onClick={() => {this.props.history.push("/toDoLists/")}}>My Tasks</Typography>
              {myAssignments.map((listItem) => (
                <ItemFragment
                  key={listItem.itemUuid} 
                  listItem={listItem}
                  onClickFunction={this.onClickFunction} 
                />)
              )}
            </Paper>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={4}>
            <Paper className={classes.paper} style={{height:'auto', maxHeight: '400px'}}>
              <Typography variant="h3" style={{ marginBottom: '20px' }} onClick={() => {this.props.history.push("/toDoLists/")}}>My Completed Tasks</Typography>
              {myComplete.map((listItem) => (
                <ItemFragment
                  key={listItem.itemUuid} 
                  listItem={listItem}
                  onClickFunction={this.onClickFunction} 
                />)
              )}
            </Paper>
          </Grid>

          <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={4}>
            <Paper className={classes.paper} style={{height:'auto', maxHeight: '400px'}}>
              <Typography variant="h3" style={{ marginBottom: '20px' }} onClick={() => {this.props.history.push("/toDoLists/")}}>All Completed Tasks</Typography>
              {allComplete.map((listItem) => (
                <ItemFragment
                  key={listItem.itemUuid} 
                  listItem={listItem}
                  onClickFunction={this.onClickFunction} 
                />)
              )}
            </Paper>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={12} md={6} lg={8}>
            <Paper className={classes.paper} style={{height:'auto', maxHeight: '400px'}}>
              <Typography variant="h3" style={{ marginBottom: '20px' }} onClick={() => {this.props.history.push("/toDoLists/")}}>All Tasks</Typography>
              {allAssignments.map((listItem) => (
                <ItemFragment
                  key={listItem.itemUuid} 
                  listItem={listItem}
                  onClickFunction={this.onClickFunction} 
                />)
              )}
            </Paper>
          </Grid>
          

        </Grid>
      </Layout>
    );
  }
}

export default withStyles(styles)(withRouter(Dashboard));
