import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import Home from './pages/home';
import Auth from './pages/auth';
import Notifications from './pages/notifications';
import Calendar from './pages/calendar';
import Event from './pages/event';
import Dashboard from './pages/dashboard';
import Assets from './pages/assets';
import Item from './pages/item';
import Items from './pages/items';
import CRM from './pages/crm';
import Profile from './pages/profile';
import ToDoListItemDetails from './pages/toDoListItemDetails';
import Company from './pages/company';
import Contact from './pages/contact';
import AllToDoLists from './pages/allToDoLists';
import OneToDoList from './pages/oneToDoList';
import Payment from './pages/payment';
import PrivateRoute from './components/privateRoute';
import * as serviceWorker from './lib/serviceWorker';
import themes from './lib/mui-theme';
import './index.css'

render(
  (
    <Router>
      <CssBaseline />
      <ThemeProvider theme={themes.main}>
        <Switch>
          <PrivateRoute path="/assets/items/:id"><Item /></PrivateRoute>
          <PrivateRoute path="/assets/items" exact><Items /></PrivateRoute>
          <PrivateRoute path="/assets" exact><Assets /></PrivateRoute>
          <PrivateRoute path="/toDoLists/list/:listUuid"><OneToDoList /></PrivateRoute>
          <PrivateRoute path="/toDoLists/itemDetails/:listUuid/:itemUuid"><ToDoListItemDetails /></PrivateRoute>
          <PrivateRoute path="/toDoLists" exact><AllToDoLists /></PrivateRoute>
          <PrivateRoute path="/dashboard" exact><Dashboard /></PrivateRoute>
          <PrivateRoute path="/crm" exact><CRM /></PrivateRoute>
          <PrivateRoute path="/crm/company/:companyName"><Company /></PrivateRoute>
          <PrivateRoute path="/crm/contact/:contactUuid"><Contact /></PrivateRoute>
          <PrivateRoute path="/payment" exact><Payment /></PrivateRoute>
          <PrivateRoute path="/notifications" exact><Notifications /></PrivateRoute>
          <PrivateRoute path="/calendar" exact><Calendar /></PrivateRoute>
          <PrivateRoute path="/events/event/:eventUuid"><Event /></PrivateRoute>
          <PrivateRoute path="/profile"><Profile /></PrivateRoute>
          <Route path="/forgotPassword" component={Auth} />
          <Route path="/resetPassword" component={Auth} />
          <Route path="/login" component={Auth} />
          <Route path="/register" component={Auth} />
          <Route path="/" component={Home} />
          
        </Switch>
      </ThemeProvider>
    </Router>
  ),
  document.getElementById('root'));

serviceWorker.register();
