import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import arrayToTree from 'array-to-tree';
import { get } from 'lodash';

import Layout from '../components/layout';
import ToDoList from '../components/newToDoList';
import Header from '../components/header';
import fetch from '../lib/get';
import Button from '../components/button';
import Hidden from '../components/hidden';
import colors from '../lib/colors';



class OneToDoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toDoList: { title: "" },
            addItemFormHidden: true,
        }
    }
    async componentDidMount() {
        let res = await fetch(`/toDoLists/getToDoList?listUuid=${this.props.match.params.listUuid}`, { credentials: "include", method: "get" });
        let data = res.body;

        data.status[0].listItems = arrayToTree(data.status[0].listItems, {
            parentProperty: 'parentUuid',
            customID: 'itemUuid'
        });
        this.setState({ toDoList: data.status[0] });
    }

    toggleAddItemForm() {
        this.setState({ addItemFormHidden: !this.state.addItemFormHidden});
    }

    render() {
        const head = get(this.props.location, 'state.listItem.itemUuid', false);
        const showLink = get(this.props.location, 'state.showLink', false);
        if (this.state.toDoList.title === "") {
            return (
                <div></div>
            )
        } else {
            return (
                <Layout>
                    <Header />
                    <Paper>
                        <Link to="/toDoLists">Back to all lists</Link>
                        <ToDoList
                            head={head}
                            showNested={true}
                            list={this.state.toDoList}
                            updateFunction={() => { }}
                            dependencyHidden={true}
                            showLink={showLink}
                            allowComplete={true}
                            history={this.props.history}
                        />
                    </Paper>
                </Layout >
            )
        }
    }
}

export default withRouter(OneToDoList);
