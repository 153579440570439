import React from 'react';

import Hidden from  './hidden';
import colors from '../lib/colors';

const styles = {
  success: {
    color: colors.primaryColor,
    fontSize: '1.5rem',
    width: '100%',
    overflowWrap: 'break-word',
  },
}

function Success(props) {
  return (
    <Hidden hidden={!props.success}>
      <div style={styles.success}>{props.success}</div>
    </Hidden>
  );
}

export default Success;
