import React from 'react';

export default function Logo() {
  return (
    <svg
       version="1.1"
       id="svg3498"
       viewBox="0 0 224.57004 224.57002">
      <defs
         id="defs3500" />
      <g
         transform="translate(10.231448,11.089886)"
         id="layer1">
        <g
           id="g4941"
           transform="matrix(2.1761078,0,0,2.1761078,-126.43774,-221.95687)">
          <g
             transform="rotate(23.064155,105,148.5)"
             id="g4943">
            <path
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}}
               d="m 57.57798,168.82405 94.84404,-40.6481"
               id="path4945" />
            <path
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}}
               d="M 57.096293,129.33884 152.9037,167.66115"
               id="path4947" />
            <path
               id="path4949"
               d="M 53.769296,142.39014 156.2307,154.60987"
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} />
            <path
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}}
               d="M 53.933597,155.8578 156.0664,141.14219"
               id="path4951" />
            <path
               id="path4953"
               d="m 63.687844,117.59335 82.624306,61.81329"
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} />
            <path
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}}
               d="m 73.09475,107.9541 63.81049,81.09179"
               id="path4955" />
          </g>
          <g
             id="g4957"
             transform="rotate(38.064155,105,148.5)">
            <path
               id="path4959"
               d="m 57.57798,168.82405 94.84404,-40.6481"
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} />
            <path
               id="path4961"
               d="M 57.096293,129.33884 152.9037,167.66115"
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} />
            <path
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}}
               d="M 53.769296,142.39014 156.2307,154.60987"
               id="path4963" />
            <path
               id="path4965"
               d="M 53.933597,155.8578 156.0664,141.14219"
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} />
            <path
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}}
               d="m 63.687844,117.59335 82.624306,61.81329"
               id="path4967" />
            <path
               id="path4969"
               d="m 73.09475,107.9541 63.81049,81.09179"
               style={{fill:'none',fillRule:'evenodd',stroke:'#ffffff',strokeWidth:4.5999999,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} />
          </g>
        </g>
        <g
           id="text4971"
           aria-label="TENSILE"
           style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontSize:'34.90111923px',lineHeight:'125%',fontFamily:'Hiragino Kaku Gothic Std',letterSpacing:'0px',wordSpacing:'0px',fill:'#333333',fillOpacity:1,stroke:'none',strokeWidth:'0.87252796px',strokeLinecap:'butt',strokeLinejoin:'miter',strokeOpacity:1}}>
          <path
             id="path3627"
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontFamily:'Hiragino Kaku Gothic Std',fill:'#333333',strokeWidth:'0.87252796px'}}
             d="m 34.101643,92.871222 3.943827,0 c 2.51288,0 3.874024,0.0698 5.444574,0.244308 l 0,-6.666113 c -1.605451,0.174505 -3.001496,0.244307 -5.444574,0.244307 l -15.28669,0 c -2.128969,0 -4.04853,-0.104703 -5.200267,-0.244307 l 0,6.666113 c 2.477979,-0.209406 3.071298,-0.244308 5.270069,-0.244308 l 4.118332,0 0,17.590168 c 0,2.19877 -0.0698,3.62971 -0.279209,5.27007 l 7.713147,0 c -0.209406,-1.64036 -0.279209,-3.17601 -0.279209,-5.27007 l 0,-17.590168 z" />
          <path
             id="path3629"
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontFamily:'Hiragino Kaku Gothic Std',fill:'#333333',strokeWidth:'0.87252796px'}}
             d="m 68.075701,109.44925 c -1.500748,0.17451 -3.036398,0.24431 -5.688883,0.24431 l -9.004488,0 0,-5.86339 7.538641,0 c 2.408178,0 3.594816,0.0698 5.235168,0.24431 l 0,-6.456705 c -2.094067,0.209406 -2.931694,0.244308 -5.235168,0.244308 l -7.538641,0 0,-5.130465 9.074291,0 c 2.338375,0 3.594815,0.0698 5.200266,0.244308 l 0,-6.526509 c -1.640352,0.174505 -2.82699,0.244307 -5.200266,0.244307 l -11.133458,0 c -2.722287,0 -3.699518,-0.0349 -5.130464,-0.104703 0.209407,1.710155 0.279209,2.931694 0.279209,5.095563 l 0,19.056016 c 0,2.40817 -0.0698,3.69951 -0.279209,5.09556 1.500748,-0.0698 2.477979,-0.1047 5.130464,-0.1047 l 10.993853,0 c 3.001496,0 4.257937,0.0698 5.758685,0.2443 l 0,-6.52651 z" />
          <path
             id="path3631"
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontFamily:'Hiragino Kaku Gothic Std',fill:'#333333',strokeWidth:'0.87252796px'}}
             d="m 81.670233,91.056364 c -1.361143,-2.024265 -2.128968,-3.420309 -2.477979,-4.36264 l -7.573543,0 c 0.209407,1.605452 0.279209,3.176002 0.279209,5.200267 l 0,18.357989 c 0,2.58268 -0.0698,4.11833 -0.279209,5.47948 l 7.433938,0 c -0.209406,-1.50075 -0.279209,-3.0364 -0.279209,-5.44458 l 0,-7.81785 c 0,-1.71015 -0.0698,-3.629716 -0.244307,-5.200267 0.977231,1.84976 1.57055,2.896797 2.51288,4.292837 l 6.456707,9.73741 c 1.186638,1.81486 1.989364,3.1062 2.617584,4.43245 l 7.643345,0 c -0.174505,-1.46585 -0.279209,-3.0713 -0.279209,-5.20027 l 0,-18.532496 c 0,-2.268572 0.0698,-3.734419 0.279209,-5.30497 l -7.433938,0 c 0.209407,1.605452 0.279209,3.001497 0.279209,5.30497 l 0,7.957456 c 0,1.95446 0.0698,3.55991 0.209407,5.16537 -0.837627,-1.53565 -1.500749,-2.65249 -2.512881,-4.15324 l -6.631213,-9.911916 z" />
          <path
             id="path3633"
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontFamily:'Hiragino Kaku Gothic Std',fill:'#333333',strokeWidth:'0.87252796px'}}
             d="m 101.2165,106.97127 c 0.2443,1.60546 0.41881,2.40818 0.76782,3.24581 0.73293,1.98936 2.02427,3.49011 3.76932,4.46734 1.88466,1.08194 4.81636,1.71016 7.78295,1.71016 4.08343,0 7.15473,-1.01214 9.179,-2.9666 1.60545,-1.57055 2.44307,-3.80422 2.44307,-6.4218 0,-3.21091 -1.25644,-5.54928 -3.76932,-7.119833 -1.32624,-0.837626 -3.00149,-1.430945 -5.65398,-1.989363 -4.11833,-0.837627 -4.11833,-0.837627 -4.81635,-1.151737 -1.11684,-0.523517 -1.64035,-1.256441 -1.64035,-2.233672 0,-1.605451 1.57055,-2.757188 3.69951,-2.757188 2.65249,0 3.90893,0.94233 4.32774,3.210903 l 7.39904,-0.802726 c -0.45371,-1.989364 -0.87253,-3.036397 -1.74506,-4.188134 -1.88466,-2.617584 -5.27006,-3.978728 -9.70251,-3.978728 -6.87552,0 -11.20326,3.385409 -11.20326,8.760181 0,2.826991 1.18664,5.165366 3.38541,6.701017 1.29134,0.90743 2.40818,1.32624 5.61908,2.02426 3.62972,0.83763 4.50225,1.08194 5.23517,1.43095 0.90743,0.41881 1.46585,1.36114 1.46585,2.44308 0,1.95446 -1.60545,3.1411 -4.29284,3.1411 -1.77996,0 -3.2458,-0.59332 -4.08343,-1.64035 -0.52352,-0.69803 -0.76783,-1.32625 -0.90743,-2.58269 l -7.25943,0.69802 z" />
          <path
             id="path3635"
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontFamily:'Hiragino Kaku Gothic Std',fill:'#333333',strokeWidth:'0.87252796px'}}
             d="m 128.74202,86.693724 c 0.20941,1.53565 0.27921,2.547782 0.27921,5.165366 l 0,18.707 c 0,2.33837 -0.0698,3.80422 -0.27921,5.16537 l 7.67825,0 c -0.20941,-1.46585 -0.27921,-2.75719 -0.27921,-5.16537 l 0,-18.707 c 0,-2.547782 0.0698,-3.73442 0.27921,-5.165366 l -7.67825,0 z" />
          <path
             id="path3637"
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontFamily:'Hiragino Kaku Gothic Std',fill:'#333333',strokeWidth:'0.87252796px'}}
             d="m 156.51459,115.73146 c 2.79209,0 4.50225,0.0698 5.79359,0.2443 l 0,-6.70101 c -1.64035,0.20941 -2.82699,0.24431 -5.79359,0.24431 l -8.44607,0 0,-17.310959 c 0,-2.722287 0.0349,-3.699518 0.27921,-5.514377 l -7.60844,0 c 0.2094,1.675254 0.2792,3.176002 0.2792,5.619081 l 0,18.427795 c 0,2.33837 -0.0698,3.87402 -0.2792,5.09556 1.32624,-0.0698 2.75718,-0.1047 5.16536,-0.1047 l 10.60994,0 z" />
          <path
             id="path3639"
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:800,fontStretch:'normal',fontFamily:'Hiragino Kaku Gothic Std',fill:'#333333',strokeWidth:'0.87252796px'}}
             d="m 186.54865,109.44925 c -1.50075,0.17451 -3.0364,0.24431 -5.68888,0.24431 l -9.00449,0 0,-5.86339 7.53864,0 c 2.40818,0 3.59481,0.0698 5.23517,0.24431 l 0,-6.456705 c -2.09407,0.209406 -2.9317,0.244308 -5.23517,0.244308 l -7.53864,0 0,-5.130465 9.07429,0 c 2.33837,0 3.59481,0.0698 5.20027,0.244308 l 0,-6.526509 c -1.64036,0.174505 -2.827,0.244307 -5.20027,0.244307 l -11.13346,0 c -2.72229,0 -3.69952,-0.0349 -5.13046,-0.104703 0.2094,1.710155 0.27921,2.931694 0.27921,5.095563 l 0,19.056016 c 0,2.40817 -0.0698,3.69951 -0.27921,5.09556 1.50075,-0.0698 2.47798,-0.1047 5.13046,-0.1047 l 10.99385,0 c 3.0015,0 4.25794,0.0698 5.75869,0.2443 l 0,-6.52651 z" />
        </g>
      </g>
    </svg>
  );
}