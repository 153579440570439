import React, { Component, Fragment, useRef } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';
import { Anchorme } from 'react-anchorme'

import get from '../../lib/get';
import colors from '../../lib/colors'
import ToDoListItemForm from '../toDoListItemForm';
import arrayToTree from 'array-to-tree';
import { RestaurantRounded } from '@material-ui/icons';
import Hidden from '../../components/hidden';
import Button from '../button';
import ListItem from '../listItem';


import TextField from '../../components/textField';
import MUITextField from '@material-ui/core/TextField';

import EventTile from '../tiles/eventTile';
import TodoTile from '../tiles/todoTile';
import AssetTile from '../tiles/assetTile';



import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    },
    tableStyle: {
        fontSize: '1.25em',
        verticalAlign: 'top',
    }
};

class ContactTile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: {
                firstName: "",
                lastName: "",
                companyName: "",
                phoneNumber: "",
                email: "",
                attributes: [],
                contactUuid: null,
            }
        }
    }

    async componentDidMount() {
        try{
          const path = `/contacts/getContact?contactUuid=${this.props.uuid}`
          const res = await get(path, { credentials: 'include', method: 'GET' });
          if ('contactUuid' in res.body.status[0]) {
            this.setState({ contact: res.body.status[0] });
          }
        }catch(err){
          this.setState({ error: err.message })
        }
      }

    render() {
        return(
            <Fragment>
            <Link to={`/crm/contact/` + this.props.uuid}>
                <h4 style={{color: colors.primaryColor}}>{this.state.contact.firstName} {this.state.contact.lastName}</h4>
            </Link>
            <h1>Phone Number: {this.state.contact.phoneNumber}</h1>
            <h1>Email Address: <a href={`mailto:${this.state.contact.email}`}>{this.state.contact.email}</a></h1>
            <h1>Company Name: <Link to={`/crm/company/${this.state.contact.companyName}`}>{this.state.contact.companyName}</Link></h1>
            </Fragment>
        )
    }

}

export default withStyles(styles)(ContactTile);