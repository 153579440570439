import React, { Component } from 'react';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import themes from '../lib/mui-theme';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
};

class UsersDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {users:[],gotUsers:false, userUuid: "default"};
    }

    handleChange = (event) => {
        this.props.selectFunction(event.target.value);
        this.setState({userUuid: event.target.value});
    }

    render() {
        let userSelects = [<MenuItem key="default" value="default">Assign to:</MenuItem>];
        this.props.orgUsers.map((user) => {
            return userSelects.push(<MenuItem key={user.userUuid} value={user.userUuid} >{user.displayName}</MenuItem>);
        })
        return (
            <ThemeProvider theme={themes.select}>
                <Select labelId="Assign to"
                    id="assign"
                    value={this.state.userUuid}
                    onChange={this.handleChange}
                    style={{fontWeight: '400'}}
                    >
                    {userSelects}
                </Select>
            </ThemeProvider>
        )
    }
}

export default withStyles(styles)(UsersDropdown);
