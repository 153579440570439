import React, { Component } from 'react';
import FormData from 'form-data';
import { TextField } from '@material-ui/core';
import fetch from '../lib/get';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import Button from '../components/button'


class ToDoListItemForm extends Component {
    constructor(props) {
        super(props);
        this.state = {value: '',parentUuid: ''};
        if (this.props.parentUuid !== undefined) {
            this.state.parentUuid = this.props.parentUuid
        }
    }

    handleChange = (event) => {
        this.setState({ value: event.target.value });
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        let data = {
            listUuid: this.props.listUuid,
            parentUuid: this.state.parentUuid,
            itemTitle: this.state.value
        };
        let formData = new FormData()
        formData.append("listUuid", this.props.listUuid);
        formData.append("itemTitle", this.state.value);
        formData.append("parentUuid", this.props.parentUuid)
        this.setState({ value: "" });
        let res = await fetch("/toDoLists/addListItem", {
            method: 'post',
            credentials: 'include',
            body: formData,
        });
        data = res.body;

        if (data.success === true) {
            this.props.updateFunction();
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <TextField autoFocus label="Add an item to this list" value={this.state.value} onKeyDown={this.KeyPress} onChange={this.handleChange} inputProps={{style: {fontSize: '2em'}}} />
                {this.props.showButton ? <Button action={this.handleSubmit}>Add Item</Button> : null}       
            </form>
        )
    }
}

export default ToDoListItemForm;