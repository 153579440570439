import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { noop } from 'lodash';



export default function StyledField(props) {
  
  const { label, action, length, type } = props;
  const style = {
    marginBottom: '15px',
    width: props.width || '400px',
    fontWeight: 500,
    margin: '10px',
  }
  const inputProps = {
    maxLength: length,
    style: { fontSize: '15px', paddingLeft: '10px' },
  };
  return <TextField width={800} style={style} id={props.id} value={props.value} style={props.style} label={label} type={type} onChange={action} inputProps={inputProps} />
}

StyledField.defaultProps = {
  label: '',
  action: noop,
  style: {},
  type: 'text',
  length: '300',
};

StyledField.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
};
