import React, { Component } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import Layout from '../components/layout';
import Header from '../components/header';
import ToDoList from '../components/toDoList';
import CreateListForm from '../components/createListForm';
import fetch from '../lib/get';


class AllToDoLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toDoLists: []
        }
    }
    updateFunction = async () => {
        let res = await fetch('/toDoLists/all', { credentials: "include", method: "get" })
        let data = res.body;
        this.setState({ toDoLists: data.status });
    }
    async componentDidMount() {
        let res = await fetch('/toDoLists/all', { credentials: "include", method: "get" });
        this.setState({ toDoLists: res.body.status });
    }
    render() {
        return (
            <Layout>
                <Header />
                                    
                    <Grid container spacing={5} style={{marginTop:'0px'}}>
                        <Grid xs={12} s={12} md={12} lg={12} item>
                            <Paper style={{height: '200px', overflow: "auto"}}>   
                                <CreateListForm history={this.props.history} updateFunction={() => { this.updateFunction() }} />
                            </Paper>
                         </Grid>
                        {this.state.toDoLists.map(toDoList => (
                            <Grid xs={12} s={12} md={6} lg={6} item>
                                <Paper style={{height: '400px', overflow: "hidden"}}>            
                                    <ToDoList key={toDoList.listUuid} list={toDoList} updateFunction={() => { }} displayForm={false} showLink={true} allowComplete={false} showNested={false} />
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
            </Layout>
        )
    }
}

export default withRouter(AllToDoLists);
