import { rgbToHex } from "@material-ui/core";

export default {
  primaryColor: '#078080',
  secondaryColor: '#f45d48',
  thirdColor: '#f8f5f2',
  button: '#078080',
  text: '#232323',
  background: '#f8f5f2',
  red: '#F55547',
  green: '#50FA83'
};
