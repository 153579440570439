import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';

const main = {
  overrides: {
    MuiPaper: {
      root: {
        padding: '20px',
      },
    },
  },
  palette: {
    primary: { main: colors.primaryColor },
    secondary: { main: colors.secondaryColor },
  },
  text: '#232323',
  typography: {
    h1: {
      color: colors.secondaryColor,
      fontWeight: 900,
    },
    h2: {
      color: colors.secondaryColor,
      fontWeight: 700,
    },
    h3: {
      color: colors.secondaryColor,
      fontWeight: 700,
    },
    h5: {
      color: colors.text,
      fontWeight: 500,
    },
    fontSize: 14,
  },
};

const select = {
  ...main,
  overrides: {
      MuiPaper: {
        root: {
          padding: '0px',
          fontSize: '1.5em',
        },
      },
    },
};

export default {
  main: createMuiTheme(main),
  select: createMuiTheme(select),
};
