import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { forEach, pullAt, get, reject, omit } from 'lodash';
import query from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import { Paper, IconButton, Dialog, DialogTitle, Snackbar, GridList } from '@material-ui/core';
import { Delete, Edit, Add, Close, Save } from '@material-ui/icons';

import moment from "moment"

import Layout from '../components/layout';
import AttributeAdd from '../components/attributeAdd';
import Hidden from '../components/hidden';
import Button from '../components/button';
import Header from '../components/header';
import Comments from '../components/comments';
import Error from '../components/error';
import Success from '../components/success';
import TextField from '../components/textField';
import fetch from '../lib/get';
import colors from '../lib/colors';


const styles = {
  content: {
    display: 'flex',
    marginTop: '50px',
    fontSize: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '2rem',
    alignItems: 'center',
  },
  attributes: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
  },
  attribute: {
    margin: '20px 0',
  },
  editFields: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '10px 0',
  },
  goBack: {
    width: '100px',
    marginTop: '20px',
  },
  image: {
    height: '300px',
    width: '300px'
  },
  buttonGroup: {
    display: 'flex',
    marginTop: '20px',
  },
  cancel: {
    backgroundColor: colors.secondaryColor,
    width: '80px',
    margin: '0 20px',
  },
  edit: {
    width: '80px',
    backgroundColor: colors.successDark,
  },
  dialogMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    fontSize: '16px',
    margin:'20px',
  },
  snack: {
    backgroundColor: colors.background,
    fontSize: '12px',
  },
};

class Item extends Component {
  constructor(props) {
    super(props);

    this.buildItem = this.buildItem.bind(this);
    this.handleAsk = this.handleAsk.bind(this);
    this.handleDeleteAttribute = this.handleDeleteAttribute.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditValue = this.handleEditValue.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.handleAddAttributes = this.handleAddAttributes.bind(this);

    this.state = {
      success: false,
      error: false,
      item: {},
      open: false,
      openItem: false,
      index: null,
      add: [],
      edit: [],
    };
  }
  async componentDidMount() {
    try{
      const queryString = query.stringify({ uuid: this.props.match.params.id });
      const res = await fetch(`/things/getThing?${queryString}`, { credentials: 'include', method: 'GET' });
      this.setState({ item: {...res.body.status[0]} });
    }catch(err){
      this.setState({ error: err.message })
    }
  }
  buildItem () {
    const attributes = this.state.item.attributes || [];
    const list = [];
    let item = "";
    forEach(attributes, (attr, index) => {
      if (attr.attributeType != "image") {
        item = (
          <div style={styles.attribute} key={index}>
            <h2>{`${attr.attributeName}: ${attr.attributeValue}`}</h2>
            {item.valid && (
              <Fragment>
              <IconButton color="primary" aria-label="edit" onClick={() => this.handleEdit(index)}>
                <Edit />
              </IconButton>
              <IconButton color="secondary" aria-label="delete" onClick={() => this.handleAsk(index)}>
                <Delete />
              </IconButton>
              </Fragment>
            )}
          </div>
        );
      }
      if (attr.attributeType == "image") {
        item = (
          <div style={styles.attribute} key={index}>
            <img style={{maxWidth: "80vw"}} src={`https://api.tensile.co/uploads/photos/${attr.attributeValue}`} />
            <IconButton color="secondary" aria-label="delete" onClick={() => this.handleAsk(index)}>
              <Delete />
            </IconButton>
          </div>
        );
      }
      
      if(attr.edit) {
        item = (
          <div style={styles.attribute} key={index}>
            {`${attr.attributeName}:`}
            <TextField style={{width: '100px', marginLeft: '20px'}} value={this.state.edit[index].attributeValue} action={(val) => this.handleEditValue(index, val)} maxLength="36" />
            <IconButton color="primary" aria-label="save" onClick={() => this.handleSubmitEdit(index)}>
              <Save />
            </IconButton>
            <IconButton color="secondary" aria-label="cancel-edit" onClick={() => this.handleCancelEdit(index)}>
              <Close />
            </IconButton>
          </div>
        )}
      list.push(item);
    });
    return list;
  }
  handleAsk(index) {
    this.setState({ open: true, index });
  }
  handleEdit(index) {
    const item = {...this.state.item};
    const attributes = [...this.state.item.attributes];
    const edit = {...this.state.edit};
    edit[index] = {...attributes[index] };
    attributes[index].edit = true; 
    item.attributes = attributes;
    this.setState({ item, edit });
  }
  handleEditValue(index, e) {
    const edit = {...this.state.edit};
    edit[index].attributeValue = e.target.value;
    this.setState({ edit });
  }
  async handleSubmitEdit(index) {
    const { attributeUuid, attributeName, attributeValue } = this.state.edit[index];
    const queryString = query.stringify({ attributeName, attributeValue, attributeUuid, uuid: this.state.item.uuid });
    const res = await fetch(`/attributes/modifyAttribute?${queryString}`, { credentials: 'include', method: 'GET' });
    if(res.body.success) {
      const item = {...this.state.item};
      const attributes = [...this.state.item.attributes];
      let edit = {...this.state.edit};
      attributes[index] = edit[index]; 
      item.attributes = attributes;
      edit = omit(edit, [index]);
      this.setState({ item, edit });
    }
  }
  handleCancelEdit(index) {
    const item = {...this.state.item};
    const attributes = [...this.state.item.attributes];
    let edit = {...this.state.edit};
    edit = omit(edit, [index]);
    attributes[index].edit = false; 
    item.attributes = attributes;
    this.setState({ item, edit });
  }
  async handleDeleteAttribute() {
    const { index } = this.state;
    this.setState({ success: false, error: false, open: false, index: null });
    try{
      const queryString = query.stringify({ attributeUuid: this.state.item.attributes[index].attributeUuid });
      const res = await fetch(`/attributes/deleteAttribute?${queryString}`, { credentials: 'include', method: 'GET' });
      if(res.body.success) {
        const item = {...this.state.item};
        pullAt(item.attributes, [index]);
        this.setState({ item, success: 'Delete successful', error: false });
      }
    }catch {
      this.setState({ success: false, error: 'An error occured. Please try again later.' })
    }
  }
  async handleDeleteItem() {
    try{
      const queryString = query.stringify({ assetUuid: this.state.item.uuid});
      const res = await fetch(`/things/deleteThing?${queryString}`, { credentials: 'include', method: 'GET' });
      if(res.body.success) {
        this.props.history.push('/assets/items');
      }
    }catch {
      this.setState({ success: false, error: 'An error occured. Please try again later.' })
    }
  }
  handleAdd() {
    this.setState({ add: [...this.state.add, { attributeName: '', attributeValue: '' }]});
  }

  makeDate(date) {
    return(moment(date).format("MM/DD/YYYY"));
  }


  async handleAddAttributes() {
    const add = reject(this.state.add, ['attributeName', '']);
    if(add.length > 0) {
      forEach(add, async (attr) => {
        const queryString = query.stringify({...attr, uuid: this.state.item.uuid });
        return fetch(`/attributes/addAttribute?${queryString}`, { credentials: 'include', method: 'GET' });
      });
      const item = {...this.state.item};
      const attributes = item.attributes || [];
      item.attributes = [...attributes, ...add];
      this.setState({ item, add: [] });
    }
  }
  render() {
    const { classes } = this.props;
    const { error, success, item, index, add } = this.state;
    const name = get(item, `attributes[${index}].attributeName`, '');
    const value = get(item, `attributes[${index}].attributeValue`, '');
    console.log(item)
    return (
      <Layout>
        <Dialog open={this.state.open} onClose={() => this.setState({ open: false })}>
          <DialogTitle>Are you sure you want to delete this attribute?</DialogTitle>
          <div className={classes.dialogMessage}>
            {`${name}: ${value}`}
            <Button action={this.handleDeleteAttribute}>Continue</Button>
          </div>
        </Dialog>
        <Dialog open={this.state.openItem} onClose={() => this.setState({ openItem: false })}>
          <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
          <div className={classes.dialogMessage}>
            <Button action={this.handleDeleteItem}>Continue</Button>
          </div>
        </Dialog>
        <Header />
        <Paper style={{marginBottom: '20px'}}>
            <div className={classes.attributes}>
              <div className={classes.title} key="title">
                <h1>{this.state.item.title}</h1><br/>
                {item.valid && (
                  <IconButton onClick={this.handleAdd} color="primary" aria-label="add">
                    <Add />
                  </IconButton>
                )}
              </div>
              <h2>Created by: {this.state.item.displayName}</h2><br />
                <h2>Created on: {this.makeDate(this.state.item.creationDate)}</h2><br />
              <AttributeAdd setParentState={state => this.setState({ add: state })} attributes={add}>
                <Hidden hidden={add.length === 0}><Button action={this.handleAddAttributes}>Save</Button></Hidden>
              </AttributeAdd>
              {this.buildItem()}
              {item.valid && <Button action={() => this.setState({ openItem: true })}>Delete Item</Button>}
            </div>
        </Paper>
        <Paper>
          <Comments parentUuid={this.state.item.uuid} />
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          open={error || success}
          onClose={() => this.setState({ error: false, success: false })}
          ContentProps={{
            'aria-describedby': 'success-error-message',
            classes: {
              root: classes.snack,
            },
          }}
          message={(
            <span id="success-error-message">
              <Error error={error} />
              <Success success={success}/>
            </span>
          )}
        />
      </Layout>
    );
  }
}

export default withStyles(styles)(withRouter(Item));
