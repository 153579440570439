import ListItem from './listItem'
import React, { Component, Fragment, useRef } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';



import fetch from '../lib/get';
import colors from '../lib/colors'
import ToDoListItemForm from './toDoListItemForm';
import arrayToTree from 'array-to-tree';
import { RestaurantRounded } from '@material-ui/icons';
import Hidden from '../components/hidden';
import Button from './button';

import TextField from '../components/textField';

import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import UsersDropdown from './usersDropdown';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    },
    tableStyle: {
        fontSize: '1.25em',
        verticalAlign: 'top',
    }
};

class ToDoList extends Component {
    ws = new WebSocket('wss://api.tensile.co:8080');

    constructor(props) {
        super(props);
        this.state = {
            inCompleteItems: [],
            completeItems: [],
            addItemFormHidden: true,
            list: this.props.list,
            newItemString: "",
            confirmModalIsOpen: false,
            itemClickedUuid: false,
            archiveModelIsOpen: false,
            hiddenStatus: {},
            orgUsers: [],
            gotOrgUsers: false,
            assignedToUserUuid: "",
            dueDate: "",
        }

        this.toggleAddItemForm = this.toggleAddItemForm.bind(this);
        this.handleChangeItemString = this.handleChangeItemString.bind(this);
        this.listBody = this.listBody.bind(this);
        this.completeItem = this.completeItem.bind(this);
        this.toggleArchiveDialog = this.toggleArchiveDialog.bind(this);
        this.toggleHiddenStatus = this.toggleHiddenStatus.bind(this);
        this.handleChangeAssignedTo = this.handleChangeAssignedTo.bind(this);
        this.handleChangeDueDate = this.handleChangeDueDate.bind(this);

        const _complete = [];
        const _inComplete = [];
        
        this.props.list.listItems.forEach(function(item) {
            if (item.complete === true) {
                _complete.push(item);
            } else {
                _inComplete.push(item);
            }
        })
        
        this.state.completeItems = _complete;
        this.state.inCompleteItems = _inComplete;

    }

    componentDidMount = async () => {
        let res = await fetch(`/organization/getMembers`, { mode: 'cors', credentials: "include", method: "get" })
        let data = res.body;
        let _users = [];
        data.status.map((user) => {
            return _users.push(user);
        });
        this.setState({orgUsers:_users,gotOrgUsers:true})
        console.log("MOunted.");
    }

    notifyAddition = async () => {
        let formData = new FormData();
        formData.append("link", window.location.pathname);
        formData.append("description", "Item added to: " + this.state.list.title);
        formData.append("flood", "flood");
        let res = await fetch("/notifications/addNotification", { body: formData, mode: 'cors', credentials: 'include', method: 'post' })
        console.log("Notify:");
        console.log(res.body);
    }

    notifyComplete = async () => {
        let formData = new FormData();
        formData.append("link", window.location.pathname);
        formData.append("description", "Item completed on: " + this.state.list.title);
        formData.append("flood", "flood");
        let res = await fetch("/notifications/addNotification", { body: formData, mode: 'cors', credentials: 'include', method: 'post' })
        console.log("Notify:");
        console.log(res.body);
    }

    toggleAddItemForm() {
        this.setState({ addItemFormHidden: !this.state.addItemFormHidden});
    }

    toggleArchiveDialog() {
        this.setState({ archiveModelIsOpen: !this.state.archiveModelIsOpen});
    }

    toggleHiddenStatus(itemUuid) {
        let _hiddenStatus = this.state.hiddenStatus;
        if (_hiddenStatus[itemUuid] == true) {
            _hiddenStatus[itemUuid] = false
        } else {
            _hiddenStatus[itemUuid] = true;
        }
        this.setState({hiddenStatus: _hiddenStatus});

    }

    handleChangeItemString(e) {
        this.setState({ newItemString: e.target.value, error: false, success: false });
    }

    handleChangeAssignedTo(e) {
        console.log("Assigned to", e);
        this.setState({assignedToUserUuid: e});
    }

    handleChangeDueDate(e) {
        console.log(e);
        this.setState({dueDate: e});
    }

    onClickFunction(itemUuid, itemTitle) {
        this.setState({
            itemClickedUuid: itemUuid, 
            itemClickedTitle: itemTitle, 
            confirmModalIsOpen: true
        })
    }

    async refreshList() {
        let res = await fetch(`/toDoLists/getToDoList?listUuid=${this.state.list.listUuid}`, { mode: 'cors', credentials: "include", method: "get" })
        let data = res.body
        this.setState({list: data.status[0]})

        const _complete = [];
        const _inComplete = [];
        console.log(data);
        data.status[0].listItems.forEach((item) => {
            if (item.complete === true) {
                _complete.push(item);
            } else {
                _inComplete.push(item);
            }
        })
        
        this.setState({completeItems: _complete, inCompleteItems: _inComplete});
    }

    handleSubmit = async (event) => {      
        event.preventDefault();

        let formData = new FormData()
        formData.append("listUuid", this.state.list.listUuid);
        formData.append("itemTitle", this.state.newItemString);
        formData.append("assignedToUserUuid", this.state.assignedToUserUuid)
        formData.append("dueDate", this.state.dueDate == "" ? "" : this.state.dueDate.toISOString().slice(0, 19).replace("T", " "));

        this.setState({ value: "" });
        let res = await fetch("/toDoLists/addListItem", {
            method: 'post',
            credentials: 'include',
            body: formData,
        });

        if (res.body.success === true) {
            this.setState({newItemString: ""})
            this.refreshList();
        }
        //this.notifyAddition();
    }

    async completeItem() {
        let res = await fetch(`/toDoLists/completeToDoListItem?itemUuid=${this.state.itemClickedUuid}`, { credentials: "include", method: "get" })
        let data = res.body;
        if (data.success === true) {
            this.refreshList();
        }
        this.toggleHiddenStatus(this.state.itemClickedUuid);
        this.notifyComplete();
    }

    async archiveList() {
        let res = await fetch(`/toDoLists/archiveList?listUuid=${this.state.list.listUuid}`, { credentials: "include", method: "get" })
        let data = res.body;
        this.setState({archiveModelIsOpen: false});
        this.props.history.push(`/toDoLists`);
    }

    handleDatePick(e) {
        console.log(e);
    }


    listBody(items) {
        
        return items.map((item) => {
            var selectedDate = new Date();
            var creationDate = new Date(item.creationDate);
            var today = new Date();
            var distance = today - creationDate;
            var daysDistance = (distance / 86400000);
            var backgroundColor = "";
            

            if (item.complete) {
                backgroundColor = colors.green;
            } 
            if ((daysDistance > 30) && (!item.complete)) {
                backgroundColor = colors.red;
            }

            var clickFunction = () => {
                this.toggleHiddenStatus(item.itemUuid);
            }

            var completeFunction = () => {
                this.setState({itemClickedTitle: item.itemTitle, itemClickedUuid: item.itemUuid, confirmModalIsOpen: true});
            }

            var showDetails = () => {
                console.log("Showing some details.");
                console.log(this.props.history);
                this.props.history.push(`/toDoLists/itemDetails/${item.listUuid}/${item.itemUuid}`);
            }

            return(
                <Fragment>
                    <TableRow key={item.itemUuid} >
                        <TableCell style={styles.tableStyle}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div onClick={clickFunction}>{item.itemTitle}</div>
                                <Hidden hidden={!this.state.hiddenStatus[item.itemUuid]}>
                                    <div style={{marginTop: '20px'}}>
                                        <Button style={{backgroundColor: colors.thirdColor, marginRight: '20px', color: '#000000'}} action={completeFunction}>Complete</Button>
                                        <Button style={{backgroundColor: colors.thirdColor, marginRight: '20px', color: '#000000'}} action={showDetails}>Item Details</Button>
                                    </div>
                                </Hidden>    
                            </div>
                        </TableCell>
                        <TableCell style={styles.tableStyle}>{item.displayName}</TableCell>
                        <TableCell style={styles.tableStyle}>{item.assignedToDisplayName}</TableCell>
                        <TableCell style={styles.tableStyle}>{moment(item.creationDate).format("MM/DD/YYYY")}</TableCell>
                        <TableCell style={styles.tableStyle}>{item.dueDate != "" ? moment(item.dueDate).format("MM/DD/YYYY") : ""}</TableCell>
                        <TableCell style={{backgroundColor: backgroundColor}}></TableCell>                               
                    </TableRow>
                </Fragment>)
        })
    }

    render() {
        var selectedDate = null;
        return (
            <Fragment>
                <h4>{this.state.list.title}</h4>

                <Hidden hidden={!this.state.addItemFormHidden}>
                    <Button style={{backgroundColor: colors.secondaryColor}} action={this.toggleAddItemForm}>Add Item</Button>
                </Hidden>

                <Hidden hidden={this.state.addItemFormHidden}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <form style={styles.forms} onSubmit={this.handleSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                                <div style={{fontSize: '2em', color: colors.secondaryColor}}>
                                    Item Title
                                </div>
                                <div>
                                    <TextField id="newListItemInput" label="List Item" action={this.handleChangeItemString} value={this.state.newItemString} />
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>

                                    <div style={{fontSize: '1.5em', color: colors.primaryColor}}>
                                        (Optional) Due Date
                                        </div>
                                    <div>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            label="Basic example"
                                            value={this.state.dueDate == "" ? new Date() : this.state.dueDate}
                                            onChange={this.handleChangeDueDate}
                                            animateYearScrolling
                                        />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px', marginLeft: '30px'}}>

                                    <div style={{fontSize: '1.5em', color: colors.primaryColor}}>
                                        (Optional) Assigned To:
                                        </div>
                                    <div style={{marginTop: '16px'}}>
                                        <UsersDropdown orgUsers={this.state.orgUsers} selectFunction={this.handleChangeAssignedTo} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button style={{backgroundColor: colors.secondaryColor, marginRight: '20px', marginTop: '20px'}} action={this.handleSubmit}>Add Item</Button>            
                            </div>
                        </form>
                    </div>
                    
                </Hidden>

                <Table>
                    <TableHead>
                        <TableCell style={styles.tableStyle}>Item Title</TableCell>
                        <TableCell style={styles.tableStyle}>Created By</TableCell>
                        <TableCell style={styles.tableStyle}>Assigned To</TableCell>
                        <TableCell style={styles.tableStyle}>Created On</TableCell>
                        <TableCell style={styles.tableStyle}>Due Date</TableCell>
                        <TableCell style={styles.tableStyle}>Status</TableCell>
                    </TableHead>
                    <TableBody>
                        {this.listBody(this.state.inCompleteItems)}
                        {this.listBody(this.state.completeItems)}
                    </TableBody>
                </Table>
                
                
                <Button style={{backgroundColor: colors.secondaryColor, marginTop: '20px'}} action={this.toggleArchiveDialog}>Archive this list</Button>
                
                
                <Dialog open={this.state.confirmModalIsOpen} onClose={() => this.setState({ confirmModalIsOpen: false })}>
                    <DialogTitle>Confirm completion</DialogTitle>
                    <div className={this.props.classes.dialogMessage}>
                        Have you completed {this.state.itemClickedTitle}?
                        <Button action={() => { this.completeItem(this.state.itemClickedUuid); this.setState({ confirmModalIsOpen: false }) }}>Yes</Button>
                    </div>
                </Dialog>
                <Dialog open={this.state.archiveModelIsOpen} onClose={() => this.setState({ archiveModelIsOpen: false })}>
                    <DialogTitle>Archive this list?</DialogTitle>
                    <div className={this.props.classes.dialogMessage}>
                        Are you sure you want to archive {this.state.list.title}?
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Button style={{marginRight: '20px', backgroundColor: colors.secondaryColor}} action={() => { this.archiveList(); this.setState({ deleteModalIsOpen: false }) }}>Yes</Button>
                        <Button action={() => {this.setState({archiveModelIsOpen: false})}}>No</Button>
                        </div>                  
                    </div>
                </Dialog>

            </Fragment >
        )
    }
}

export default withStyles(styles)(ToDoList);