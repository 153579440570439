import React, { Component } from 'react';
import { forEach, reject } from 'lodash';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { Paper, Grid } from '@material-ui/core';

import get from '../lib/get';
import Layout from '../components/layout';
import Error from '../components/error';
import Success from '../components/success';
import Header from '../components/header';
import ContactTable from '../components/contactTable';
import AttributeAdd from '../components/attributeAdd';
import TextField from '../components/textField';
import Button from '../components/button';
import Hidden from '../components/hidden';

import colors from '../lib/colors';

var arrow = "U+0279C";

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 50px',
    minWidth: '300px',
    flex: 1,
  },
  sectionTitle: {
    fontSize: '1.5rem',
    color: 'black',
  },
  fields: {
    margin: '10px 0',
    width: '200px',
  },
  addItem: {
    alignSelf: 'center',
    width: '200px',
    marginTop: '20px',
    backgroundColor: colors.primaryColor,
  },
  addDetail: {
    width: '100px',
  },
  cancel: {
    backgroundColor: colors.errorColor,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    backgroundColor: colors.secondaryColor,
    marginLeft: '20px',
  },
}

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.handleChangeContactFirstName = this.handleChangeContactFirstName.bind(this);
    this.handleChangeContactLastName = this.handleChangeContactLastName.bind(this);
    this.handleChangeContactEmail = this.handleChangeContactEmail.bind(this);
    this.handleChangeContactPhoneNumber = this.handleChangeContactPhoneNumber.bind(this);
    this.handleChangeContactCompanyName = this.handleChangeContactCompanyName.bind(this);
    this.handleAddContact = this.handleAddContact.bind(this);
    this.addDetail = this.addDetail.bind(this);
    this.buildItems = this.buildItems.bind(this);
    this.toggleAddContactForm = this.toggleAddContactForm.bind(this);


    this.state = {
      contactName: '',
      attributes: [],
      error: false,
      success: false,
      items: [],
      addContactFormHidden: true,
      companyName: this.props.match.params.companyName,
      contactCompanyName: this.props.match.params.companyName,
    };
  }
  async componentDidMount() {
    try{
      const res = await get(`/contacts/getCompany?companyName=${this.state.companyName}`, { credentials: 'include', method: 'GET' });
      this.setState({ items: res.body.status });
    }catch(err){
      this.setState({ error: err.message })
    }
  }
  handleChangeContactFirstName(e) {
    this.setState({ contactFirstName: e.target.value, error: false, success: false });
  }
  handleChangeContactLastName(e) {
    this.setState({ contactLastName: e.target.value, error: false, success: false });
  }
  handleChangeContactPhoneNumber(e) {
    this.setState({ contactPhoneNumber: e.target.value, error: false, success: false });
  }
  handleChangeContactEmail(e) {
    this.setState({ contactEmail: e.target.value, error: false, success: false });
  }
  handleChangeContactCompanyName(e) {
    this.setState({ contactCompanyName: e.target.value, error: false, success: false });
  }

  toggleAddContactForm() {
    this.setState({ addContactFormHidden: !this.state.addContactFormHidden});
  }

  async handleAddContact() {
    this.setState({ error: false, success: false });
    const attributes = reject(this.state.attributes, ['attributeName', '']);
    const body = { firstName: this.state.contactFirstName, 
                   lastName: this.state.contactLastName,
                   phoneNumber: this.state.contactPhoneNumber,
                   companyName: this.state.contactCompanyName,
                   email: this.state.contactEmail,
                   attributes }
    var willSubmit = false;
    var errorMessage = "";
    if (this.state.contactFirstName) {
      if (this.state.contactPhoneNumber || this.state.contactEmail) {
        willSubmit = true;
      } else {
        errorMessage = "You must specify either a phone number or an email address.";
      }
    } else {
      errorMessage = "You must specify at LEAST a first name."
    }
    if (willSubmit == true) {
      try {
        console.log("addcontact: body:");
        console.log(body);
        await get('/contacts/addContact', { credentials: 'include', method: 'POST', body: JSON.stringify(body) });
        this.setState({ success: 'Success!', error: false, attributes: [], contactName: '' });
        const getContacts = await get(`/contacts/getCompany?companyName=${this.state.companyName}`, { credentials: 'include', method: 'GET' });
        console.log("Get contacts body:")
        console.log(getContacts.body.status);
        this.setState({contactCompanyName: "", contactEmail: "", contactPhoneNumber: "", contactFirstName: "", contactLastName: ""});
        this.setState({ items: getContacts.body.status });
      } catch(err) {
        return this.setState({ error: err.message });
      }
    } else {
      this.setState({error: errorMessage});
    }
  }
  addDetail() {
    this.setState({ attributes: [...this.state.attributes, { attributeName: '', attributeValue: '' }], error: false, success: false });
  }
  buildItems() {
    const list = [];
    forEach(this.state.items, (item) => {
      list.push({
        firstName: item.firstName,
        uuid: item.uuid,
        attributes: [{ attributeName: 'creationDate', attributeValue: moment(item.creationDate).format('MM-DD-YYYY')}, { attributeName: 'username', attributeValue: item.username }]});
    });
    console.log("BuildItems");
    console.log(list);
    return list;
  }
  render() {
    const { contactName, error, success, attributes, items } = this.state;
    const button = <Button style={{backgroundColor: colors.secondaryColor, width: '200px'}} action={() => this.props.history.push(`/contacts/contact`)}>Search Items</Button>;
    return (
      <Layout>
        <Header />
        <div style={styles.content}>
          <div style={styles.section}>
            <header style={styles.sectionTitle}>
              <Hidden hidden={!this.state.addContactFormHidden}>
                <Button style={{backgroundColor: colors.secondaryColor}} action={this.toggleAddContactForm}>New Contact</Button>
              </Hidden>
            </header>
            <Hidden hidden={this.state.addContactFormHidden}>
              <h2>New Contact Details:</h2>
              <form style={styles.forms}>
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    <TextField label="First Name" action={this.handleChangeContactFirstName} value={this.state.contactFirstName}/>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField label="Last Name" action={this.handleChangeContactLastName} value={this.state.contactLastName}/>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField label="Email Address" action={this.handleChangeContactEmail} value={this.state.contactEmail}/>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField label="Phone Number" action={this.handleChangeContactPhoneNumber} value={this.state.contactPhoneNumber}/>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField label="Company Name" value={this.state.companyName}/>
                  </Grid>
                </Grid>
              </form>            
              <AttributeAdd setParentState={state => this.setState({ attributes: state })} attributes={attributes} />
              <Error error={error} /><Success success={success}/>
              <div style={{ marginTop: '20px' }}>
                <Button style={{backgroundColor: colors.secondaryColor, marginRight: '20px'}} action={this.handleAddContact}>Add Contact</Button>
                <Button style={{backgroundColor: colors.thirdColor, marginRight: '20px', color:'#000000'}} action={this.addDetail}>Add Detail</Button>
                <Button style={{backgroundColor: colors.thirdColor, marginRight: '20px', color:'#000000'}} action={this.toggleAddContactForm}>Cancel</Button>
                
              </div>
            </Hidden>
          </div>
          <div style={styles.section}>
            <Paper>
              <Link to={`/crm/`}>Back to All Contacts</Link>
              <ContactTable contacts={items} button={button} history={this.props.history} />
            </Paper>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Contacts);
