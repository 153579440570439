import React, { Component } from 'react';
import { forEach, reject } from 'lodash';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { Paper, Grid } from '@material-ui/core';

import get from '../lib/get';
import Layout from '../components/layout';
import Error from '../components/error';
import Success from '../components/success';
import Header from '../components/header';
import Comments from '../components/comments';
import ContactTable from '../components/contactTable';
import AttributeAdd from '../components/attributeAdd';
import TextField from '../components/textField';
import Button from '../components/button';
import Hidden from '../components/hidden';

import colors from '../lib/colors';

const styles = {
}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        firstName: "",
        lastName: "",
        companyName: "",
        phoneNumber: "",
        email: "",
        attributes: [],
        contactUuid: this.props.match.params.contactUuid,
      }
    }
  }
  async componentDidMount() {
    try{
      const path = `/contacts/getContact?contactUuid=${this.props.match.params.contactUuid}`
      const res = await get(path, { credentials: 'include', method: 'GET' });
      this.setState({ contact: res.body.status[0] });
    }catch(err){
      this.setState({ error: err.message })
    }
  }
  
  additionalAttributes = () => {
    let resp = [];
    console.log(this.state.contact.attributes);
    if (this.state.contact.attributes.length > 0) {
      resp.push(<hr />);
      resp.push(<h1>Additional Attributes</h1>);
      this.state.contact.attributes.forEach(function(attribute) {
        resp.push(<h2>{attribute.attributeName}: {attribute.attributeValue}</h2>)
      })
    }
    return(resp);
  }

  render() {
    return (
      <Layout>
        <Header />
        <div style={styles.content}>
          <div style={styles.section}>
            <header style={styles.sectionTitle}>
            </header>
          </div>
          <div style={styles.section}>

            <Paper>
              <Link to={`/crm/`}>All Contacts</Link>
                  <h4 style={{color: colors.primaryColor}}>{this.state.contact.firstName} {this.state.contact.lastName}</h4>
                  <h1>Phone Number: {this.state.contact.phoneNumber}</h1>
                  <h1>Email Address: <a href={`mailto:${this.state.contact.email}`}>{this.state.contact.email}</a></h1>
                  <h1>Company Name: <Link to={`/crm/company/${this.state.contact.companyName}`}>{this.state.contact.companyName}</Link></h1>

                  {this.additionalAttributes()}

            </Paper>
            <Paper style={{marginTop: "20px", marginBottom: "100px"}}>
              <Comments parentUuid={this.props.match.params.contactUuid} title={this.state.contact.firstName + " " + this.state.contact.lastName}/>
            </Paper>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Contact);
