import errors from './errors';

const baseUrl = process.env.REACT_APP_BASE_URL;

export default async function get(url, options) {
  return fetch(`${baseUrl}${url}`, options)
    .then(async res => {
      const result = await res.json();
      if(result.success) {
        return { result: res, body: result };
      }
      return errors(result.status.errors);
    });
}
