import ListItem from './listItem'
import React, { Component, Fragment, useRef } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';
import { Anchorme } from 'react-anchorme'

import fetch from '../lib/get';
import colors from '../lib/colors'
import ToDoListItemForm from './toDoListItemForm';
import arrayToTree from 'array-to-tree';
import { RestaurantRounded } from '@material-ui/icons';
import Hidden from '../components/hidden';
import Button from './button';

import TextField from '../components/textField';
import MUITextField from '@material-ui/core/TextField';


import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    },
    tableStyle: {
        fontSize: '1.25em',
        verticalAlign: 'top',
    }
};

class Comments extends Component {
    ws = new WebSocket('wss://api.tensile.co:8080');

    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            commentText: "",
            commentsHash: "",
        }
        this.commentsList = this.commentsList.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
    }

    async refreshComments() {
        let res = await fetch(`/comments/getComments?parentUuid=${this.props.parentUuid}`, { mode: 'cors', credentials: "include", method: "get" })
        let data = res.body
        this.setState({comments: data.status});
    }

    componentDidMount() {
        this.refreshComments();
    }

    notify = async () => {
        let formData = new FormData();
        formData.append("link", window.location.pathname);
        formData.append("description", "Commented added to: " + this.props.title);
        formData.append("flood", "flood");
        let res = await fetch("/notifications/addNotification", { body: formData, mode: 'cors', credentials: 'include', method: 'post' })
        console.log("Notify:");
        console.log(res.body);
    }

    handleSubmit = async (event) => {      
        event.preventDefault();

        let formData = new FormData()
        formData.append("commentText", this.state.commentText);
        formData.append("parentUuid", this.props.parentUuid)
        this.setState({ commentText: "" });
        let res = await fetch("/comments/addComment", {
            method: 'post',
            credentials: 'include',
            body: formData,
        });

        if (res.body.success === true) {
            this.notify();
            this.refreshComments();
        }
    }

    handleCommentChange(e) {
        this.setState({ commentText: e.target.value});
    }

    commentsList(comments) {
        let _comments = [];
        comments.forEach(function(comment) {
            _comments.push(
                <div>
                    <h1><Anchorme target="_blank">{comment.commentText}</Anchorme></h1>
                    Submitted by: {comment.displayName}
                </div>
            )
        })
        return(_comments)
    }

    render() {

        const commentStyle = {
            container: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            textField: {
                width: 300,
                margin: 100,
            },
            //style for font size
            resize:{
              fontSize:50
            },
            }
        return (
            <div>
               <h4 style={{color: colors.secondaryColor}}>Comments</h4>
               <div style={{marginBottom: '60px'}}>
                    <form style={styles.forms} onSubmit={this.handleSubmit}>
                        <MUITextField
                            id="filled-multiline-static"
                            label="Comment"
                            multiline
                            rows={4}
                            variant="filled"
                            value={this.state.commentText}
                            onChange={this.handleCommentChange}
                            style={{
                                width: '100%',
                                backgroundColor: '#FFFFFF',
                            }}
                        />
                        <Button style={{backgroundColor: colors.secondaryColor, marginTop: '20px'}} action={this.handleSubmit}>Submit</Button>
                    </form>
                </div>
                <Fragment key={this.state.commentsHash}>
                    {this.commentsList(this.state.comments)}
                </Fragment>
            </div>
        )
    }
}

export default withStyles(styles)(Comments);