import React, { Component } from 'react';
import { forEach, reject } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Paper } from '@material-ui/core';

import get from '../lib/get';
import Layout from '../components/layout';
import Error from '../components/error';
import Success from '../components/success';
import Header from '../components/header';
import Table from '../components/table';
import AttributeAdd from '../components/attributeAdd';
import TextField from '../components/textField';
import Button from '../components/button';
import colors from '../lib/colors';

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 50px',
    minWidth: '300px',
    flex: 1,
  },
  sectionTitle: {
    fontSize: '1.5rem',
    color: 'black',
  },
  fields: {
    margin: '10px 0',
    width: '200px',
  },
  addItem: {
    alignSelf: 'center',
    width: '200px',
    marginTop: '20px',
    backgroundColor: colors.primaryColor,
  },
  addDetail: {
    width: '100px',
  },
  cancel: {
    backgroundColor: colors.errorColor,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    backgroundColor: colors.secondaryColor,
    marginLeft: '20px',
  },
}

class Assets extends Component {
  constructor(props) {
    super(props);

    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleAddThing = this.handleAddThing.bind(this);
    this.addDetail = this.addDetail.bind(this);
    this.buildItems = this.buildItems.bind(this);

    this.state = {
      title: '',
      attributes: [],
      error: false,
      success: false,
      items: [],
    };
  }
  async componentDidMount() {
    try{
      const res = await get('/things/getThings', { credentials: 'include', method: 'GET' });
      this.setState({ items: res.body.status });
    }catch(err){
      this.setState({ error: err.message })
    }
  }
  handleChangeTitle(e) {
    this.setState({ title: e.target.value, error: false, success: false });
  }
  async handleAddThing() {
    this.setState({ error: false, success: false });
    const attributes = reject(this.state.attributes, ['attributeName', '']);
    const body = { title: this.state.title, attributes }
    try {
      await get('/things/addThing', { credentials: 'include', method: 'POST', body: JSON.stringify(body) });
      this.setState({ success: 'Success!', error: false, attributes: [], title: '' });
      const getThings = await get('/things/getThings', { credentials: 'include', method: 'GET' });
      this.setState({ items: getThings.body.status });
    } catch(err) {
      return this.setState({ error: err.message });
    }
  }
  addDetail() {
    this.setState({ attributes: [...this.state.attributes, { attributeName: '', attributeValue: '' }], error: false, success: false });
  }
  buildItems() {
    const list = [];
    forEach(this.state.items, (item) => {
      list.push({
        title: item.title,
        uuid: item.uuid,
        attributes: [{ attributeName: 'creationDate', attributeValue: moment(item.creationDate).format('MM-DD-YYYY')}, { attributeName: 'username', attributeValue: item.username }]});
    });
    return list;
  }
  render() {
    const { title, error, success, attributes, items } = this.state;
    const button = <Button style={{backgroundColor: colors.secondaryColor, width: '200px'}} action={() => this.props.history.push(`/assets/items`)}>Search Items</Button>;
    return (
      <Layout>
        <Header />
        <div style={styles.content}>
          <div style={styles.section}>
            <header style={styles.sectionTitle}>
              Add an Item
            </header>
            <div style={styles.fields}>
              <TextField value={title} id="title" label="Title" action={this.handleChangeTitle} maxLength="36" />
            </div>
            <AttributeAdd setParentState={state => this.setState({ attributes: state })} attributes={attributes} />
            <Error error={error} /><Success success={success}/>
            <div style={{ marginTop: '20px' }}>
              <Button action={this.addDetail}>Add Detail</Button>
              <Button style={styles.button} action={this.handleAddThing}>Add Item</Button>
            </div>
          </div>
          <div style={styles.section}>
            <Paper>
              <Table history={this.props.history} rows={items} button={button} />
            </Paper>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Assets);
