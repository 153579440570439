import React from 'react';
import PropTypes from 'prop-types';
import { noop, forEach } from 'lodash';

import TextField from './textField';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}

function AutoComplete(props) {
  const { label, id, action, type, maxLength, autoComplete, value } = props;
  const options = [];
  forEach(autoComplete, (item, index) => {
    options.push(<option key={index}>{item.attributeName}</option>);
  })
  return (
    <div style={styles.container}>
      <TextField value={value} style={{ marginRight: '20px' }} label={label} list={`${id}list`} length={maxLength} action={action} type={type}/>
      <datalist id={`${id}list`}>
        {options}
      </datalist>
    </div>
  );
}

AutoComplete.defaultProps = {
  id: '',
  label: '',
  value: '',
  type: 'text',
  maxLength: '300',
  action: noop,
};

AutoComplete.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  maxLength: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default AutoComplete;
