import React from 'react';
import PropTypes from 'prop-types';
import { find, forEach, reject, countBy, orderBy, take } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Checkbox,
} from '@material-ui/core';

import TableToolbar from './tableToolbar';
import TableHead from './tableHead';

function buildAttributes (items, searchFields) {
  let list = [];
  forEach(items, (item) => {
    forEach(item.attributes, (attr) => {
      list.push(attr.attributeName);
    });
  });
  list = countBy(list);
  let ordered = [];
  forEach(list, (attr, key) => {
    ordered.push({ attributeName: key, occurence: attr })
  })
  ordered = orderBy(ordered, ['occurence'], ['desc'])
  if(searchFields) {
    forEach(searchFields, (attr) => {
      ordered = reject(ordered, ['attributeName', attr.attributeName]);
      ordered.unshift(attr);
    });
  }
  return take(ordered, 9);
}

function buildColumns (items) {
  const list = [];
  list.push({ id: 'title', numeric: false, disablePadding: true, label: 'title' });
  forEach(items, (item, index) => {
    list.push({ id: item.attributeName, numeric: false, disablePadding: true, label: item.attributeName });
  });
  return list;
}

function buildRows(rows, columns) {
  const newRows = [];
  const filteredColumns = reject(columns, function(i) {return i.id === 'title' || i.id === 'more' });
  forEach(rows, (row) => {
    forEach(filteredColumns, (column) => {
      const exists = find(row.attributes, ['attributeName', column.id]) || { attributeValue: '----' };
      row[column.id] = exists.attributeValue;
    });
    newRows.push(row);
  });
  return newRows;
}

function desc(a, b, orderBy, order) {
  if(b[orderBy] === '----'){
    if(a[orderBy] === '----') {
      return 0;
    }
    if(order === 'desc') {
      return -1;
    }
    return 1;
  }

  if(a[orderBy] === '----'){
    if(order === 'desc') {
      return 1;
    }
    return -1;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy, 'desc') : (a, b) => -desc(a, b, orderBy, order, 'asc');
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
  },
  cell: {
    fontSize: '12px',
    cursor: 'pointer',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function SortingTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('title');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const attributeNames = buildAttributes(props.rows, props.currentSearch);
  const columns = buildColumns(attributeNames);
  const rows = buildRows(props.rows, columns);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleClickSearch = (name, value) => {
    if(value === '----' || props.history.location.pathname === '/assets/items') {
      return;
    }
    props.history.push(`/assets/items?${name}=${value}`)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <TableToolbar button={props.button} numSelected={selected.length} />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <TableHead
            classes={classes}
            columns={columns}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                
                const rowList = [];
                const filteredColumns = reject(columns, function(i) {return i.id === 'title' || i.id === 'more' });
                forEach(filteredColumns, (column, key) => {
                  rowList.push(
                    <TableCell
                      onClick={() => {
                        if(props.history.location.pathname === '/assets/items'){
                          return props.history.push(`/assets/items/${row.uuid}`);
                        }
                        handleClickSearch(column.id, row[column.id])
                      }}
                      className={`${classes.cell} column-${key+2}`}
                      key={key}
                      align="left"
                    >
                      {row[column.id]}
                    </TableCell>);
                });

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell className={classes.cell} key="checkbox" padding="checkbox">
                      <Checkbox
                        onClick={event => handleClick(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell className={`${classes.cell} column-1`} key="title" onClick={() => props.history.push(`/assets/items/${row.uuid}`)} component="th" id={labelId} scope="row" padding="none">
                      {row.title}
                    </TableCell>
                    {rowList}
                    <TableCell className={classes.cell} key="more" align="left" onClick={() => props.history.push(`/assets/items/${row.uuid}`)}>...</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}

SortingTable.defaultProps = {
  rows: [],
  history: {},
};

SortingTable.propTypes = {
  rows: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  currentSearch: PropTypes.array,
  button: PropTypes.element,
};
