import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import arrayToTree from 'array-to-tree';
import { get } from 'lodash';

import Layout from '../components/layout';
import ToDoList from '../components/toDoList';
import Header from '../components/header';
import fetch from '../lib/get';

class Payment extends Component {

    async getToken() {
        try {
            const brainTreeClientToken = await fetch('/payments/getBraintreeClientToken', { credentials: 'include', method: 'get' });
            this.setState({ clientToken: brainTreeClientToken.body.status});
        } catch(err) {
            this.setState({ error: err.message })
        }
    }

    async componentDidMount() {
        await this.getToken();
        console.log("Some shit.");
        console.log(this.clientToken);
        console.log(this);
        const form = window.document.getElementById("payment-form");
        window.braintree.dropin.create({
            authorization: this.state.clientToken,
            container: '#dropin-container'
        }, (error, dropinInstance) => {
            if (error) console.error(error);
            form.addEventListener('submit', event => {
                event.preventDefault();
                dropinInstance.requestPaymentMethod((error, payload) => {
                    if (error) console.error(error);
                    window.document.getElementById('nonce').value = payload.nonce;
                    form.submit();
                });
            });
        });
    }

    render() {
        return(
            <form id="payment-form" action="https://api.tensile.co/payments/makePayment" method="post">
                <div id="dropin-container"></div>
                <input type="submit" />
                <input type="hidden" id="nonce" name="payment_method_nonce" />
            </form>
        );
    }
}

export default withRouter(Payment);
