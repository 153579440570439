import React, { Component } from 'react';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import themes from '../lib/mui-theme';
import colors from '../lib/colors'

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
};

class UserString extends Component {
    constructor(props) {
        super(props);
    }

    
    render() {
        return (
            <span style={{color: colors.primaryColor}}>
                {this.props.displayName}
            </span>
        )
    }
}

export default withStyles(styles)(UserString);
