import React, { Component, Fragment, useRef } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';
import { Anchorme } from 'react-anchorme'

import get from '../../lib/get';
import colors from '../../lib/colors'
import ToDoListItemForm from '../toDoListItemForm';
import arrayToTree from 'array-to-tree';
import { RestaurantRounded } from '@material-ui/icons';
import Hidden from '../../components/hidden';
import Button from '../button';
import ListItem from '../listItem';


import TextField from '../../components/textField';
import MUITextField from '@material-ui/core/TextField';

import EventTile from '../tiles/eventTile';
import AssetTile from '../tiles/assetTile';



import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    },
    tableStyle: {
        fontSize: '1.25em',
        verticalAlign: 'top',
    }
};

class ToDoTile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: {
                title: null,
                listItems: [],
            }
        }
    }

    async componentDidMount() {
        try{
          const path = `/toDoLists/getToDoList?listUuid=${this.props.uuid}`
          const res = await get(path, { credentials: 'include', method: 'GET' });
          if ('listUuid' in res.body.status[0]) {
            this.setState({ list: res.body.status[0] });
            }
        }catch(err){
          this.setState({ error: err.message })

        }
      }

    render() {
        return(
            <Fragment>
                <Link to={`/toDoLists/list/` + this.props.uuid}>
                    <h4 style={{color: colors.primaryColor}}>{this.state.list.title}</h4>
                </Link>
            </Fragment>
        )
    }

}

export default withStyles(styles)(ToDoTile);