import ListItem from './listItem'
import React, { Component, Fragment, useRef } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Paper, Grid, Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';
import { Anchorme } from 'react-anchorme'

import fetch from '../lib/get';
import colors from '../lib/colors'
import ToDoListItemForm from './toDoListItemForm';
import arrayToTree from 'array-to-tree';
import { RestaurantRounded } from '@material-ui/icons';
import Hidden from '../components/hidden';
import Button from './button';

import TextField from '../components/textField';
import MUITextField from '@material-ui/core/TextField';
import Tile from '../components/tile';


import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    },
    tableStyle: {
        fontSize: '1.25em',
        verticalAlign: 'top',
    }
};

class TileContainer extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        let tiles = [];
        this.props.tiles.forEach((tile) => {
            tiles.push(
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper>
                        <Tile type={tile.type} uuid={tile.uuid} />
                    </Paper>
                </Grid>
            )
        })
        console.log(tiles);
        return(
            <Grid style={{marginTop: '20px'}} container spacing={2}>
                {tiles}
            </Grid>
        )
    }
}

export default (TileContainer);