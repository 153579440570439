import React, { Component } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Layout from '../components/layout';
import Header from '../components/header';
import ToDoList from '../components/toDoList';
import CreateListForm from '../components/createListForm';
import fetch from '../lib/get';
import colors from '../lib/colors'



class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: []
        }
    }

    readNotification = (notificationUuid) => {
        let formData = new FormData()
        formData.append("notificationUuid", notificationUuid);
        fetch("/notifications/readNotification", {
            method: 'post',
            credentials: 'include',
            body: formData,
        });
    }

    renderNotifications = (notifications) => {
        let _notifications = [];
        notifications.forEach((notification) => {
            let onClickFunction = () => {
                this.props.history.push(notification.link)
                this.readNotification(notification.notificationUuid)
            }
            _notifications.push(   
                <div style={{color: notification.isRead ? '' : colors.secondaryColor}} onClick={onClickFunction}>
                    <h1>{notification.description}</h1>
                    By: {notification.displayName} on {moment(notification.creationDate).format("MM/DD/YYYY")}
                </div>
            )
        })
        return(_notifications)
    }

    updateFunction = async () => {
        let res = await fetch('/notifications/getNotifications', { credentials: "include", method: "get" })
        let data = res.body;
        this.setState({ notifications: data.status });
    }
    async componentDidMount() {
        let res = await fetch('/notifications/getNotifications', { credentials: "include", method: "get" })
        let data = res.body;
        this.setState({ notifications: data.status });
        console.log("Body on notifications: ");
        console.log(data);
    }
    render() {
        return (
            <Layout>
                <Header />
                <Paper>
                    {this.renderNotifications(this.state.notifications)}
                </Paper>
            </Layout>
        )
    }
}

export default withRouter(Notifications);
