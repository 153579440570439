import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import query from 'query-string';
import { Select, MenuItem, Dialog, DialogTitle } from '@material-ui/core';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';

import TextField from '../components/textField';
import Button from '../components/button';
import Error from '../components/error';
import Success from '../components/success';
import Notifier from '../components/notifier';
import colors from '../lib/colors';
import get from '../lib/get';
import themes from '../lib/mui-theme';


const styles = {
  appHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '2rem',
    color: colors.primaryColor,
    textDecoration: 'none',
  },
  username: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.5rem',
    color: colors.secondaryDark,
    textDecoration: 'none',
  },
  logout: {
    backgroundColor: colors.secondaryColor,
    marginTop: '15px',
  },
  invite: {
    marginLeft: '20px',
  },
  close: {
    backgroundColor: colors.secondaryColor,
    marginLeft: '20px',
  },
  modal: {
    padding: '25px',
    fontSize: '12px',
  },
  modalText: {
    marginBottom: '15px',
  },
  menuItem: {
    textDecoration: 'none',
    fontSize: '15px',
    cursor: 'pointer',
    color: colors.text,
  },
  select: {
    fontSize: '15px',
  },
}

class Header extends Component {
  constructor(props) {
    super(props);

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);

    this.state = {
      whoami: '',
      open: false,
      email: '',
      success: false,
      error: false,
    }
  }
  async componentDidMount() {
    const res = await get('/auth/whoami', { credentials: 'include', method: 'GET' });
    this.setState({ whoami: res.body.status });
  }
  handleChangeEmail(email) {
    this.setState({ email });
  }
  async handleSendEmail() {
    const queryString = query.stringify({ mailTo: this.state.email });
    try {
      await get(`/invites/invite?${queryString}`, { credentials: 'include', method: 'GET' })
      this.setState({ success: 'Invite Success!', error: false });
    } catch {
      this.setState({ error: 'An error occured. Please try again later.', success: false });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.appHeader}>
        <div>
          <div style={{display:'flex', flexDirection:'row', align:'flex-start'}}>
            <div>
              <Link className={classes.headerTitle} to="/dashboard">
                Tensile
              </Link>
            </div>
            <div style={{marginLeft: '10px'}}>
              <Notifier />
            </div>
          </div>
          <Dialog open={this.state.open} onClose={() => this.setState({ open: false })}>
            <DialogTitle>Add Team Member</DialogTitle>
            <div style={styles.modal}>
              <div className={classes.modalText}>Invite a member of your team to share your inventory with you:</div>
              <TextField value={this.state.email} label="Email Address" action={(e) => this.handleChangeEmail(e.target.value)} />
              <Error error={this.state.error} /><Success success={this.state.success} />
              <div style={{ paddingTop: '20px' }}>
                <Button action={this.handleSendEmail}>Submit</Button>
                <Button style={styles.close} action={() => this.setState({ success: false, open: false })}>Close</Button>
              </div>
            </div>
          </Dialog>
        </div>

        <div className={classes.username}>
          <ThemeProvider theme={themes.select}>
            <Select className={classes.select} value="" displayEmpty>
              <MenuItem className={classes.select} value="" disabled>
                {`Welcome, ${this.state.whoami.displayName}`}
              </MenuItem>
              <Link style={styles.menuItem} to="/dashboard">
                <MenuItem className={classes.select} value={1}>Dashboard</MenuItem>
              </Link>
              <Link style={styles.menuItem} to="/assets">
                <MenuItem className={classes.select} value={2}>Asset Tracking</MenuItem>
              </Link>
              <Link style={styles.menuItem} to="/toDoLists">
                <MenuItem className={classes.select} value={3}>ToDo Lists</MenuItem>
              </Link>
              <Link style={styles.menuItem} to="/crm">
                <MenuItem className={classes.select} value={3}>CRM</MenuItem>
              </Link>
              <Link style={styles.menuItem} to="/calendar">
                <MenuItem className={classes.select} value={3}>Calendar</MenuItem>
              </Link>
              <Link style={styles.menuItem} to="/profile">
                <MenuItem className={classes.select} value={4}>Profile</MenuItem>
              </Link>
              <Link style={styles.menuItem} to="/subscription">
                <MenuItem className={classes.select} value={5}>Subscription</MenuItem>
              </Link>
              <div style={{ display: 'flex', flexDirection: 'column', margin: '15px', alignContent: 'center' }}>
                <Button action={() => this.setState({ open: true })}>Invite User</Button>
                <Link style={{ textDecoration: 'none' }} to="/login">
                  <Button style={styles.logout} action={() => get('/auth/logout', { credentials: 'include', method: 'GET' })}>Logout</Button>
                </Link>
              </div>
            </Select>
          </ThemeProvider>
        </div>
      </div >
    );
  }
}

export default withStyles(styles)(Header);
