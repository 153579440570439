import ListItem from './listItem'
import React, { Component, Fragment } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';

import fetch from '../lib/get';
import colors from '../lib/colors'
import Button from './button';
import ToDoListItemForm from './toDoListItemForm';
import arrayToTree from 'array-to-tree';
import Hidden from '../components/hidden';


const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    }
};

function getUuid(sorted, expand) {
    sorted.map((item) => {
        expand[item.itemUuid] = true;
        if (item.children.length > 0) {
            getUuid(item.children, expand);
        }
    });
}

function getHead(items, targetUuid, list) {
    forEach(items, (item) => {
        if (item.itemUuid === targetUuid) {
           list.push(item);
        }
        if (item.children) {
            getHead(item.children, targetUuid, list);
        }
    });
}

class ToDoList extends Component {
    ws = new WebSocket('wss://api.tensile.co:8080');

    constructor(props) {
        super(props);
        let _list = this.props.list
        let sorted = arrayToTree(_list.listItems, {
            parentProperty: 'parentUuid',
            customID: 'itemUuid'
        });
        console.log(sorted);
        if(this.props.head) {
            let list = [];
            getHead(sorted, this.props.head, list);
            sorted = list;
        }
        _list.listItems = sorted;
        const expand = {};
        getUuid(sorted, expand);
        this.state = { depth: 0, expand, orgUsers: [], goOrgUsers: false, itemClickedUuid: '', value: '', list: _list, confirmModalIsOpen: false, deleteModalIsOpen: false, listItems: []};
    }
    listForm = () => {
        return (
            <form onSubmit={this.handleSubmit}>
                <input type="text" value={this.state.value} onChange={this.handleChange} />
                <input type="submit" value="Submit" />
            </form>
        );
    }
    getNewState = async () => {
        let res = await fetch(`/toDoLists/getToDoList?listUuid=${this.state.list.listUuid}`, { mode: 'cors', credentials: "include", method: "get" })
        let data = res.body
        data.status[0].listItems = arrayToTree(data.status[0].listItems, {
            parentProperty: 'parentUuid',
            customID: 'itemUuid'
          });
        const expand = {};
        getUuid(data.status[0].listItems, expand);
          
        this.setState({ list: data.status[0], expand });
    }
    completeItem = async (itemUuid) => {
        let res = await fetch(`/toDoLists/completeToDoListItem?itemUuid=${itemUuid}`, { credentials: "include", method: "get" })
        let data = res.body;
        if (data.success === true) {
            this.getNewState();
        }
    }
    deleteItem = async (itemUuid) => {
        let res = await fetch(`/toDoLists/deleteListItem?itemUuid=${itemUuid}`, { credentials: "include", method: "get" })
        let data = res.body;
        if (data.success === true) {
            this.getNewState();
        }
    }
    handleChange = (event) => {
        this.setState({ value: event.target.value });
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        let data = {
            listUuid: this.state.list.listUuid,
            itemTitle: this.state.value
        };
        let formData = new FormData()
        formData.append("listUuid", this.state.list.listUuid);
        formData.append("itemTitle", this.state.value);
        this.setState({ value: "" });
        let res = await fetch("/toDoLists/addListItem", {
            method: 'post',
            credentials: 'include',
            body: formData,
        });
        data = res.body;

        if (data.success === true) {
            this.getNewState();
        }
    }
    titleString = () => {
        if (this.props.showLink === true) {
            return (
                <div>
                    <Link onClick={() => this.getNewState()} style={{ textDecoration: 'none' }} to={`/toDoLists/list/${this.state.list.listUuid}`}>
                        <Typography variant="h3">
                            {this.props.list.title}
                        </Typography>
                    </Link>
                    <Typography variant="h5" style={{ fontStyle: 'italic' }}>
                        Created by {this.props.list.displayName}
                    </Typography>
                </div>
            )
        } else {
            return (
                <Typography variant="h3">{this.props.list.title}</Typography>
            );
        }
    }
    onClickFunction = (item) => {
        if ((this.props.allowComplete === true) && (item.complete === false)) {
            this.setState({ itemClickedTitle: item.itemTitle, itemClickedUuid: item.itemUuid, confirmModalIsOpen: true });
        }
    }
    deleteFunction = (item) => {
        this.setState({ itemClickedTitle: item.itemTitle, itemClickedUuid: item.itemUuid, deleteModalIsOpen: true });
        console.log(this.state);
    }
    expandAll = (bool) => {
        let expand = {...this.state.expand};
        expand = forEach(expand, (value, key) => {
            expand[key] = bool;
        });
        this.setState({ expand });
    }
    updateExpand = (itemUuid) => {
        const expand = {...this.state.expand};
        expand[itemUuid] = !expand[itemUuid];
        this.setState({ expand });
    }
    mapItems = (items,depth) => {
        let holding = [];
        items = orderBy(items, ["complete"], ['asc']);
        items.map((listItem) => {
            holding.push(
                <ListItem key={listItem.itemUuid} 
                    complete={listItem.complete} 
                    listItem={listItem} 
                    updateFunction={this.getNewState} 
                    onClickFunction={this.onClickFunction}
                    deleteFunction={this.deleteFunction} 
                    depth={depth} 
                    dependencyHidden={this.props.dependencyHidden}
                    orgUsers={this.state.orgUsers}
                    updateExpand={this.updateExpand}
                    expanded={this.state.expand[listItem.itemUuid]}
                    showAssign={!this.props.showNested}
                />
            );
            if ((listItem.children.length > 0) && (this.props.showNested === true)) {
                holding.push(this.mapItems(listItem.children,depth+1));
            }
        });
        if (depth > 0) {
            return(<Collapse key={items[0].itemUuid} in={this.state.expand[items[0].parentUuid]} style={{borderLeft: "1px solid " + colors.secondaryColor, marginLeft: "20px"}}><List>{holding}</List></Collapse>);
        } else {
            return(<List>{holding}</List>)
        }
    }
    renderList = () => {
        if (this.state.list.listItems != null) {
            return (
                    this.mapItems(this.state.list.listItems,0)
                );
        } else {
            return (null);
        }
    }
    async componentDidMount() {
        if (this.props.showNested === true) {
        let res = await fetch(`/organization/getMembers`, { mode: 'cors', credentials: "include", method: "get" })
        let data = res.body
        let _users = [];
        data.status.map((user) => {
            return _users.push(user);
        });
        this.setState( {orgUsers:_users,gotOrgUsers:true} );
        }
        let res = await fetch('/sockets/getSocketString', { credentials: "include", method: "get" });
        let data = res.body;
        let socketString = data.status;
        this.setState({socketString: socketString})
        if (this.ws.readyState === 1) {
            this.ws.send(this.state.socketString);
        }
        this.ws.onopen = () => {
            this.ws.send(this.state.socketString);
        }
        this.ws.onmessage = evt => {
            if (evt.data === "update") {
                this.getNewState();
            }
        }
    }

    onClickFunction(itemUuid, itemTitle) {
        this.setState({itemClickedUuid: itemUuid, itemClickedTitle: itemTitle, confirmModalIsOpen: true})
    }

    render() {
        return (
            <div>
                <Dialog open={this.state.confirmModalIsOpen} onClose={() => this.setState({ confirmModalIsOpen: false })}>
                    <DialogTitle>Confirm completion</DialogTitle>
                    <div className={this.props.classes.dialogMessage}>
                        Have you completed {this.state.itemClickedTitle}?
                        <Button action={() => { this.completeItem(this.state.itemClickedUuid); this.setState({ confirmModalIsOpen: false }) }}>Yes</Button>
                    </div>
                </Dialog>
                <Dialog open={this.state.deleteModalIsOpen} onClose={() => this.setState({ deleteModalIsOpen: false })}>
                    <div className={this.props.classes.dialogMessage}>
                        Are you sure you want to delete {this.state.itemClickedTitle}?
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Button style={{marginRight: '20px', backgroundColor: colors.secondaryColor}} action={() => { this.deleteItem(this.state.itemClickedUuid); this.setState({ deleteModalIsOpen: false }) }}>Yes</Button>
                        <Button action={() => {this.setState({deleteModalIsOpen: false})}}>No</Button>
                        </div>                  
                    </div>
                </Dialog>
                {this.titleString()}
                <div style={{ margin: '20px' }}>
                    {this.props.dependencyHidden ? <ToDoListItemForm showButton listUuid={this.state.list.listUuid} updateFunction={this.getNewState} parentUuid='' /> : null}
                </div>
                <ul>
                    {this.props.showNested ? (
                        <Fragment>
                        <MuiButton color="secondary" onClick={() => this.expandAll(true)}>expand all</MuiButton>
                        <MuiButton color="secondary" onClick={() => this.expandAll(false)}>collapse all</MuiButton>
                        </Fragment>
                    ) : null}
                    {this.renderList()}
                </ul>
                
            </div >
        )
    }
}

export default withStyles(styles)(ToDoList);
