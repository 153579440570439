import ListItem from './listItem'
import React, { Component, Fragment, useRef } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forEach, map, orderBy } from 'lodash';
import { Anchorme } from 'react-anchorme'

import fetch from '../lib/get';
import colors from '../lib/colors'
import ToDoListItemForm from './toDoListItemForm';
import arrayToTree from 'array-to-tree';
import { RestaurantRounded } from '@material-ui/icons';
import Hidden from '../components/hidden';
import Button from './button';

import TextField from '../components/textField';
import MUITextField from '@material-ui/core/TextField';

import EventTile from './tiles/eventTile';
import ContactTile from './tiles/contactTile';
import TodoTile from './tiles/todoTile';
import AssetTile from './tiles/assetTile';



import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    },
    tableStyle: {
        fontSize: '1.25em',
        verticalAlign: 'top',
    }
};

class Comments extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        let resp = null;
        switch(this.props.type) {
            case 'contact':
                resp = <ContactTile uuid={this.props.uuid} />
                break;
            case 'event':
                resp = <TodoTile uuid={this.props.uuid} />
                break;
            case 'asset':
                resp = <AssetTile uuid={this.props.uuid} />
                break;
            case 'todo':
                resp = <TodoTile uuid={this.props.uuid} />
                break;
        }
        return(
            resp
        )
    }
}

export default withStyles(styles)(Comments);