import React, { Component } from 'react';
import { forEach, reject } from 'lodash';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { Paper, Grid } from '@material-ui/core';

import get from '../lib/get';
import Layout from '../components/layout';
import Error from '../components/error';
import Success from '../components/success';
import Header from '../components/header';
import Comments from '../components/comments';
import ContactTable from '../components/contactTable';
import AttributeAdd from '../components/attributeAdd';
import TextField from '../components/textField';
import Button from '../components/button';
import Hidden from '../components/hidden';

import colors from '../lib/colors';

const styles = {
}

class ToDoListItemDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        itemUuid: this.props.match.params.itemUuid,
        list: {
            creationDate: 0,
            listTitle: "",
            listItems: [],
            listUuid: this.props.match.params.listUuid,
        },
        item: {
            itemUuid: this.props.match.params.itemUuid,
            creationDate: 0,
        }
    }
  }
  async componentDidMount() {
    try{
        const path = `/toDoLists/getToDoList?listUuid=${this.props.match.params.listUuid}`
        const res = await get(path, { credentials: 'include', method: 'GET' });
        this.setState({ list: res.body.status[0] });
        this.state.list.listItems.forEach((item) => {
            if (item.itemUuid == this.props.match.params.itemUuid) {
                this.setState({item: item});
            }
        })
        console.log(this.state);
    }catch(err){
      this.setState({ error: err.message })
    }
  }

  makeDate(date) {
    return(moment(date).format("MM/DD/YYYY h:mm a"));
  }

  render() {
    var creationDate = new Date(this.state.item.creationDate);
    var today = new Date();
    var distance = today - creationDate;
    var daysDistance = (distance / 86400000);
    
    return (
      <Layout>
        <Header />
        <div style={styles.content}>
          <div style={styles.section}>
            <Paper>
                <Link to={`/toDoLists/list/${this.state.list.listUuid}`}>Back to {this.state.list.title}</Link>
                <h4 style={{color: colors.primaryColor}}>{this.state.item.itemTitle}</h4>
                <h1>Submitted By: {this.state.item.displayName}</h1>
                <h1>Submitted on: {creationDate.getMonth()}/{creationDate.getDay()}/{creationDate.getFullYear()}</h1>
                <h1>Complete: {this.state.item.complete ? "Yes" : "No"}</h1>
                {this.state.item.complete ? <h1>Completed by: {this.state.item.completedByDisplayname}</h1> : ""}
                {this.state.item.complete ? <h1>Completed on: {this.makeDate(this.state.item.completionDate)}</h1> : ""}
            </Paper>
            <Paper style={{marginTop: "20px", marginBottom: "100px"}}>
              <Comments parentUuid={this.props.match.params.itemUuid} title={this.state.item.itemTitle} />
            </Paper>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(ToDoListItemDetails);
