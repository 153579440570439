import React, { Component } from 'react';
import { forEach, reject } from 'lodash';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { Paper, Grid } from '@material-ui/core';

import get from '../lib/get';
import Layout from '../components/layout';
import Error from '../components/error';
import Success from '../components/success';
import Header from '../components/header';
import ContactTable from '../components/contactTable';
import AttributeAdd from '../components/attributeAdd';
import TextField from '../components/textField';
import Button from '../components/button';
import Hidden from '../components/hidden';
import TileContainer from '../components/tileContainer';

import colors from '../lib/colors';
import { Fragment } from 'react';

var arrow = "U+0279C";

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 50px',
    minWidth: '300px',
    flex: 1,
  },
  sectionTitle: {
    fontSize: '1.5rem',
    color: 'black',
  },
  fields: {
    margin: '10px 0',
    width: '200px',
  },
  addItem: {
    alignSelf: 'center',
    width: '200px',
    marginTop: '20px',
    backgroundColor: colors.primaryColor,
  },
  addDetail: {
    width: '100px',
  },
  cancel: {
    backgroundColor: colors.errorColor,
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    backgroundColor: colors.secondaryColor,
    marginLeft: '20px',
  },
}

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
     whoami: {
        displayName: "Ryan McDermott",
        userUuid: "a30b78fd-7acb-4f7b-9bec-727aa16efe32",
        email: "blhack@gmail.com",
        orgId: "edb3935c-3abc-4b8d-9428-480e2c59b3d4",
        errors: "",
        profileImage: "",
        }
    };
  }
  async componentDidMount() {
    try{
      const res = await get(`/auth/whoami`, { credentials: 'include', method: 'GET' });
      this.setState({ whoami: res.body.status });
    }catch(err){
      this.setState({ error: err.message })
    }
  }
  handleChangeDisplayName(e) {
    this.setState({ contactFirstName: e.target.value, error: false, success: false });
  }
  

  /*async handleAddContact() {
    this.setState({ error: false, success: false });
    const attributes = reject(this.state.attributes, ['attributeName', '']);
    const body = { firstName: this.state.contactFirstName, 
                   lastName: this.state.contactLastName,
                   phoneNumber: this.state.contactPhoneNumber,
                   companyName: this.state.contactCompanyName,
                   email: this.state.contactEmail,
                   attributes }
    var willSubmit = false;
    var errorMessage = "";
    if (this.state.contactFirstName) {
      if (this.state.contactPhoneNumber || this.state.contactEmail) {
        willSubmit = true;
      } else {
        errorMessage = "You must specify either a phone number or an email address.";
      }
    } else {
      errorMessage = "You must specify at LEAST a first name."
    }
    if (willSubmit == true) {
      try {
        console.log("addcontact: body:");
        console.log(body);
        await get('/contacts/addContact', { credentials: 'include', method: 'POST', body: JSON.stringify(body) });
        this.setState({ success: 'Success!', error: false, attributes: [], contactName: '' });
        const getContacts = await get(`/contacts/getCompany?companyName=${this.state.companyName}`, { credentials: 'include', method: 'GET' });
        console.log("Get contacts body:")
        console.log(getContacts.body.status);
        this.setState({contactCompanyName: "", contactEmail: "", contactPhoneNumber: "", contactFirstName: "", contactLastName: ""});
        this.setState({ items: getContacts.body.status });
      } catch(err) {
        return this.setState({ error: err.message });
      }
    } else {
      this.setState({error: errorMessage});
    }
  }*/

  render() {
    return (
        <Layout>
        <Header />
        <div style={styles.content}>
          <div style={styles.section}>
            <header style={styles.sectionTitle}>
            </header>
          </div>
          <div style={styles.section}>

            <Paper>
                <div class="wrapper" style={{display: 'grid', gridTemplateRows: '1fr'}}>
                    <div style={{borderLeft: 'auto', borderRight: 'auto', textAlign: 'center'}}>
                        <span style={{fontSize: '4em'}}>Ryan McDermott</span>
                    </div>
                    <div style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}}>  
                        <img src="https://www.fillmurray.com/1024/1024" width='350' style={{borderRadius: '50%'}} />
                    </div>
                </div>  
            </Paper>
            <TileContainer tiles={[{type: 'contact', uuid: '8a2326c7-8874-4c8e-bd72-06962b6ebc19'}, {type: 'todo', uuid: 'eb952511-3a60-4ebd-b974-71267a8a27ae'}]} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Profile);
