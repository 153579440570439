export default function throwError(status) {
  switch (status) {
  case 'invalid username/password combination':
    throw new Error('Invalid username/password combination.');
  case 'user exists':
    throw new Error('Username already exists. Please choose a unique username.');
  case '':
    throw new Error('You are not logged in.');
  case 'that is not a valid invite code':
    throw new Error('Your invitation code is invalid. Please request a new invitation.');
  case 'invalid reset code.':
    throw new Error('That reset code is not valid');
  default:
    throw new Error('An error occured performing this action. Please try again later.');
  };
}
