import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { noop } from 'lodash';

const style = {
  fontSize: '12px',
  fontWeight: 700,
  padding: '0 15px',
  color: 'white',
  height: '40px',
  width: '130px',
};

const Btn = styled(Button)(style);

export default function StyledButton(props) {
  return (
    <Btn disabled={props.disabled} style={props.style} variant="contained" color="primary" onClick={props.action}>
      {props.children}
    </Btn>
  );
}

StyledButton.defaultProps = {
  action: noop,
  style: {},
  disabled: false,
};

StyledButton.propTypes = {
  action: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};
